import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import IconButton from '@material-ui/core/IconButton';
import Checkbox from '@material-ui/core/Checkbox';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';

import Typography from '@material-ui/core/Typography'; // Import necessário


const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start', // Alinha à esquerda
    margin: '2rem'
  },
  headerTitle: {
    fontWeight: "bold",
    color: "#6F2CC0",
    fontFamily: "Inter, sans-serif",
    fontSize: "1.6rem",
    margin: 0,
  },
  headerSubtitle: {
    color: "#666",
    fontSize: "0.9rem",
    marginBottom: theme.spacing(2),
    fontFamily: "Inter, sans-serif",
  },
  inputContainer: {
    display: 'flex',
    width: '100%',
    marginBottom: '1rem'
  },
  input: {
    flexGrow: 1,
    marginRight: '1rem'
  },
  listContainer: {
    width: '100%',
    height: '100%',
    marginTop: '1rem',
    backgroundColor: '#f5f5f5',
    borderRadius: '5px',
  },
  list: {
    marginBottom: '5px'
  },
  checkedTask: {
    backgroundColor: '#6F2CC0',
    color: 'white',
  },
  uncheckedTask: {
    backgroundColor: '#e3e3e3',
    color: 'black',
  },
  listItem: {
    borderRadius: '5px',
    margin: '5px 0',
    padding: '10px',
  },
    // Botão "Adicionar"
    addButton: {
      backgroundColor: "#6F2CC0",
      color: "#fff",
      textTransform: "none",
      borderRadius: 8,
  
      fontWeight: "bold",
      "&:hover": {
        backgroundColor: "#5a21a3"
      }
    },
}));

const ToDoList = () => {
  const classes = useStyles();

  const [task, setTask] = useState('');
  const [tasks, setTasks] = useState([]);
  const [editIndex, setEditIndex] = useState(-1);

  useEffect(() => {
    const savedTasks = localStorage.getItem('tasks');
    if (savedTasks) {
      setTasks(JSON.parse(savedTasks));
    }
  }, []);

  useEffect(() => {
    localStorage.setItem('tasks', JSON.stringify(tasks));
  }, [tasks]);

  const handleTaskChange = (event) => {
    setTask(event.target.value);
  };

  const handleAddTask = () => {
    if (!task.trim()) {
      // Impede que o usuário crie uma tarefa sem texto
      return;
    }

    const now = new Date();
    if (editIndex >= 0) {
      // Editar tarefa existente
      const newTasks = [...tasks];
      newTasks[editIndex] = { text: task, checked: newTasks[editIndex].checked, updatedAt: now, createdAt: newTasks[editIndex].createdAt };
      setTasks(newTasks);
      setTask('');
      setEditIndex(-1);
    } else {
      // Adicionar nova tarefa
      setTasks([...tasks, { text: task, checked: false, createdAt: now, updatedAt: now }]);
      setTask('');
    }
  };

  const handleEditTask = (index) => {
    setTask(tasks[index].text);
    setEditIndex(index);
  };

  const handleDeleteTask = (index) => {
    const newTasks = [...tasks];
    newTasks.splice(index, 1);
    setTasks(newTasks);
  };

  const handleToggleCheckbox = (index) => {
    const newTasks = [...tasks];
    newTasks[index].checked = !newTasks[index].checked;
    setTasks(newTasks);
  };

  return (
    <div className={classes.root}>
    {/* Título */}
    <Typography variant="h5" className={classes.headerTitle}>
    Tarefas
    </Typography>
    {/* Subtítulo */}
    <Typography className={classes.headerSubtitle}>
    Todas as suas atividades do dia estão aqui.
    </Typography>
      <div className={classes.inputContainer}>
        <TextField
          className={classes.input}
          label="Nova tarefa"
          value={task}
          onChange={handleTaskChange}
          variant="outlined"
        />
        <Button variant="contained" 
         className={classes.addButton}
        onClick={handleAddTask}>
          {editIndex >= 0 ? 'Salvar' : 'Adicionar'}
        </Button>
      </div>
      <div className={classes.listContainer}>
        <List>
          {tasks.map((task, index) => (
            <ListItem
              key={index}
              className={`${classes.listItem} ${task.checked ? classes.checkedTask : classes.uncheckedTask}`}
            >
              <Checkbox
                checked={task.checked}
                onChange={() => handleToggleCheckbox(index)}
                style={{ color: task.checked ? 'white' : 'black' }}
              />
              <ListItemText
                primary={task.text}
                secondary={task.updatedAt.toLocaleString()}
              />
              <ListItemSecondaryAction>
                <IconButton onClick={() => handleEditTask(index)} style={{ color: task.checked ? 'white' : 'black' }}>
                  <EditIcon />
                </IconButton>
                <IconButton onClick={() => handleDeleteTask(index)} style={{ color: task.checked ? 'white' : 'black' }}>
                  <DeleteIcon />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>
      </div>
    </div>
  );
};

export default ToDoList;
