import React, { useContext, useState, useEffect } from "react";
import {
  Stepper,
  Step,
  StepLabel,
  Button,
  Typography,
  CircularProgress,
} from "@material-ui/core";
import { Formik, Form } from "formik";
import { useHistory } from "react-router-dom";

import AddressForm from "./Forms/AddressForm";
import PaymentForm from "./Forms/PaymentForm";
import ReviewOrder from "./ReviewOrder";
import CheckoutSuccess from "./CheckoutSuccess";

import api from "../../services/api";
import toastError from "../../errors/toastError";
import toast from "../../errors/toast";
import { AuthContext } from "../../context/Auth/AuthContext";
import { socketConnection } from "../../services/socket";
import { useDate } from "../../hooks/useDate";

import validationSchema from "./FormModel/validationSchema";
import checkoutFormModel from "./FormModel/checkoutFormModel";
import formInitialValues from "./FormModel/formInitialValues";

import useStyles from "./styles";


export default function CheckoutPage(props) {
  const steps = ["Dados", "Personalizar", "Revisar"];
  const { formId, formField } = checkoutFormModel;
  const planovalor = props.Invoice.value;
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(1);
  const [datePayment, setDatePayment] = useState(null);
  const [paylink, setpaylink] = useState(null);
  const [linkpay, setlinkpay] = useState(null);

  const [invoiceId, setInvoiceId] = useState(props.Invoice.id);
  const [typepay, setTypepay] = useState(props.Invoice.typepay);
  const { user } = useContext(AuthContext);
  const history = useHistory();
  const { dateToClient } = useDate();
  const corsURL = process.env.REACT_APP_BACKEND_CORS_URL;
  const assaskey = '$aact_YTU5YTE0M2M2N2I4MTliNzk0YTI5N2U5MzdjNWZmNDQ6OjAwMDAwMDAwMDAwMDAwODI3ODI6OiRhYWNoX2Q5MTg4N2MzLTFkYWItNDU0ZS1iNWEwLTk3Zjk2NGY0ZTM2OA==';

  useEffect(() => {
    const companyId = localStorage.getItem("companyId");
    const socket = socketConnection({ companyId });
    console.log(props);
    socket.on(`company-${companyId}-payment`, (data) => {

      if (data.action === "CONFIRMED") {
        toast.success(`Sua licença foi renovada até ${dateToClient(data.company.dueDate)}!`);
        setTimeout(() => {
          history.push("/");
        }, 4000);
      }
    });
  }, [history]);

  const currentValidationSchema = validationSchema[activeStep];
  const isLastStep = activeStep === steps.length - 1;


  async function _submitForm(values, actions) {
    try {
      const plan = JSON.parse(values.plan);
      const newValues = {
        firstName: values.firstName,
        lastName: values.lastName,
        address2: values.address2,
        city: values.city,
        state: values.state,
        zipcode: values.zipcode,
        country: values.country,
        useAddressForPaymentDetails: values.useAddressForPaymentDetails,
        nameOnCard: values.nameOnCard,
        cardNumber: values.cardNumber,
        cvv: values.cvv,
        plan: values.plan,
        price: plan.price,
        users: plan.users,
        connections: plan.connections,
        invoiceId: invoiceId,
      };
      const { data } = await api.post("/subscription", newValues);
      setDatePayment(data);
      actions.setSubmitting(false);
      setActiveStep(activeStep + 1);
      toast.success("Assinatura realizada com sucesso! Aguardando o pagamento.");
    } catch (err) {
      toastError(err);
    }
  }

  async function _handleSubmit(values, actions) {
    if (isLastStep) {
      // VERIFICA SE O PAGAMENTO É FEITO EM CARTÃO E SE O USUARIO JÁ POSSUI UMA CADASTRO NO ASSAS
      console.log(typepay);
      if (typepay === 'card') {
        if (user || user.customer_id) {
          try {
            const createSeason = await fetch('https://api.stripe.com/v1/checkout/sessions', {
              method: 'POST',
              headers: {
                'Authorization': `Bearer ${process.env.REACT_APP_BACKEND_STRIPE_KEY}`,
                'Content-Type': 'application/x-www-form-urlencoded'
              },
              body: new URLSearchParams({
                'success_url':  `${process.env.REACT_APP_FRONTEND_URL}/financeiro`,
                'line_items[0][price]': 'price_1R41P3FqCAxJI0sDTLtvA9aw',
                'line_items[0][quantity]': '1',
                'mode': 'subscription'
              })
            });

            const createSeasonData = await createSeason.json();
            const customer_id = createSeasonData.id;

            //   console.log(createSeasonData);
            if (createSeasonData.url) {
              // SALVA O COSTUMER_ID NO BANCO
              const requestOptions = {
                method: 'POST',
                headers: {
                  'Accept': 'application/json, text/plain, */*',
                  'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                  "user_id": user.id,
                  "code": customer_id,
                })
              };
              //FETCH
              const saveCustomer = await fetch(`${process.env.REACT_APP_BACKEND_URL}/setCustomer`, requestOptions);
              const saveCustomerData = await saveCustomer.json();

              console.log(saveCustomerData);
              console.log(saveCustomerData.success);
              if (saveCustomerData.success == "Customer_ID definido") {
                window.location.href = createSeasonData.url;
              }

              // window.location.href = responseData.url;
            }




          } catch (err) {
            console.error(err);
          }
        }
      } else {
        _submitForm(values, actions);
      }
    } else {
      setActiveStep(activeStep + 1);
      actions.setTouched({});
      actions.setSubmitting(false);
    }
  }

  function _handleBack() {
    setActiveStep(activeStep - 1);
  }
  async function _handleOpenLink() {

    window.open(linkpay, '_blank'); // '_blank' abre a URL em uma nova guia

  }
  function _renderStepContent(step, setFieldValue, setActiveStep, values) {
    switch (step) {
      case 0:
        return (
          <AddressForm
            formField={formField}
            values={values}
            setFieldValue={setFieldValue}
          />
        );
      case 1:
        return (
          <PaymentForm
            formField={formField}
            setFieldValue={setFieldValue}
            setActiveStep={setActiveStep}
            activeStep={step}
            invoiceId={invoiceId}
            values={values}
          />
        );
      case 2:
        return <ReviewOrder />;
      default:
        return <div>Not Found</div>;
    }
  }

  return (
    <React.Fragment>
      <Typography component="h1" variant="h4" align="center">
        Falta pouco!
      </Typography>
      <Stepper activeStep={activeStep} className={classes.stepper}>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <React.Fragment>
        {activeStep === steps.length ? (
          <CheckoutSuccess pix={datePayment} />
        ) : (
          <Formik
            initialValues={{
              ...user,
              ...formInitialValues,
            }}
            validationSchema={currentValidationSchema}
            onSubmit={_handleSubmit}
          >
            {({ isSubmitting, setFieldValue, values }) => (
              <Form id={formId}>
                {_renderStepContent(
                  activeStep,
                  setFieldValue,
                  setActiveStep,
                  values
                )}

                <div className={classes.buttons}>
                  {activeStep !== 1 && (
                    <Button onClick={_handleBack} className={classes.button}>
                      VOLTAR
                    </Button>
                  )}
                  <div className={classes.wrapper}>
                    {activeStep !== 1 && !paylink && (
                      <Button
                        disabled={isSubmitting}
                        type="submit"
                        variant="contained"
                        color="primary"
                        className={classes.button}
                      >
                        {isLastStep ? "PAGAR" : "PRÓXIMO"}
                      </Button>
                    )}
                    {isSubmitting && (
                      <CircularProgress
                        size={24}
                        className={classes.buttonProgress}
                      />
                    )}
                    {paylink && isLastStep && (
                      <Button
                        onClick={_handleOpenLink}
                        variant="contained"
                        color="primary"
                        className={classes.button}
                      >
                        VER FATURA
                      </Button>
                    )}

                  </div>
                </div>
              </Form>
            )}
          </Formik>
        )}
      </React.Fragment>
    </React.Fragment>
  );
}
