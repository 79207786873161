import React, { useState, useEffect, useReducer, useContext } from "react";
import toast from "../../errors/toast";
import { SocketContext } from "../../context/Socket/SocketContext";
import n8n from "../../assets/n8n.png";
import dialogflow from "../../assets/dialogflow.png";
import webhooks from "../../assets/webhook.png";
import typebot from "../../assets/typebot.png";

import {
  Avatar,
  Button,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
  Box,
  Paper,
  Grid
} from "@mui/material";

import { makeStyles } from "@material-ui/core/styles";
import { FiEdit, FiTrash } from "react-icons/fi";
import SearchIcon from "@mui/icons-material/Search";

import MainContainer from "../../components/MainContainer";
import MainHeader from "../../components/MainHeader";
import MainHeaderButtonsWrapper from "../../components/MainHeaderButtonsWrapper";
import TableRowSkeleton from "../../components/TableRowSkeleton";
import IntegrationModal from "../../components/QueueIntegrationModal";
import ConfirmationModal from "../../components/ConfirmationModal";

import api from "../../services/api";
import { i18n } from "../../translate/i18n";
import toastError from "../../errors/toastError";
import { AuthContext } from "../../context/Auth/AuthContext";
import usePlans from "../../hooks/usePlans";
import { useHistory } from "react-router-dom";


const useStyles = makeStyles((theme) => ({
  headerSubtitle: {
    color: "#666",
    fontSize: "0.9rem",
    marginBottom: theme.spacing(2),
    fontFamily: "Inter, sans-serif",
  },

}));
// -------------------------------------------
// Reducer (permanece o mesmo)
// -------------------------------------------
const reducer = (state, action) => {
  switch (action.type) {
    case "LOAD_INTEGRATIONS":
      const queueIntegration = action.payload;
      const newIntegrations = [];

      queueIntegration.forEach((integration) => {
        const integrationIndex = state.findIndex((u) => u.id === integration.id);
        if (integrationIndex !== -1) {
          state[integrationIndex] = integration;
        } else {
          newIntegrations.push(integration);
        }
      });

      return [...state, ...newIntegrations];

    case "UPDATE_INTEGRATIONS":
      const updatedIntegration = action.payload;
      const integrationIndex = state.findIndex((u) => u.id === updatedIntegration.id);

      if (integrationIndex !== -1) {
        state[integrationIndex] = updatedIntegration;
        return [...state];
      } else {
        return [updatedIntegration, ...state];
      }

    case "DELETE_INTEGRATION":
      const integrationId = action.payload;
      return state.filter((u) => u.id !== integrationId);

    case "RESET":
      return [];

    default:
      return state;
  }
};

const QueueIntegration = () => {
  const classes = useStyles(); // Definindo 'classes'
  const [loading, setLoading] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [hasMore, setHasMore] = useState(false);
  const [selectedIntegration, setSelectedIntegration] = useState(null);
  const [deletingUser, setDeletingUser] = useState(null);
  const [userModalOpen, setUserModalOpen] = useState(false);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [searchParam, setSearchParam] = useState("");
  const [queueIntegration, dispatch] = useReducer(reducer, []);
  const { user } = useContext(AuthContext);
  const { getPlanCompany } = usePlans();
  const companyId = user.companyId;
  const history = useHistory();

  const socketManager = useContext(SocketContext);

  useEffect(() => {
    async function fetchData() {
      const planConfigs = await getPlanCompany(undefined, companyId);
      if (!planConfigs.plan.useIntegrations) {
        toast.error(
          "Esta empresa não possui permissão para acessar essa página! Estamos lhe redirecionando."
        );
        setTimeout(() => {
          history.push(`/`);
        }, 1000);
      }
    }
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch({ type: "RESET" });
    setPageNumber(1);
  }, [searchParam]);

  useEffect(() => {
    setLoading(true);
    const delayDebounceFn = setTimeout(() => {
      const fetchIntegrations = async () => {
        try {
          const { data } = await api.get("/queueIntegration/", {
            params: { searchParam, pageNumber },
          });
          dispatch({ type: "LOAD_INTEGRATIONS", payload: data.queueIntegrations });
          setHasMore(data.hasMore);
          setLoading(false);
        } catch (err) {
          toastError(err);
          setLoading(false);
        }
      };
      fetchIntegrations();
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [searchParam, pageNumber]);

  useEffect(() => {
    const companyIdLocal = localStorage.getItem("companyId");
    const socket = socketManager.getSocket(companyIdLocal);

    socket.on(`company-${companyIdLocal}-queueIntegration`, (data) => {
      if (data.action === "update" || data.action === "create") {
        dispatch({ type: "UPDATE_INTEGRATIONS", payload: data.queueIntegration });
      }

      if (data.action === "delete") {
        dispatch({ type: "DELETE_INTEGRATION", payload: +data.integrationId });
      }
    });

    return () => {
      socket.disconnect();
    };
  }, [socketManager]);

  const handleOpenUserModal = () => {
    setSelectedIntegration(null);
    setUserModalOpen(true);
  };

  const handleCloseIntegrationModal = () => {
    setSelectedIntegration(null);
    setUserModalOpen(false);
  };

  const handleSearch = (event) => {
    setSearchParam(event.target.value.toLowerCase());
  };

  const handleEditIntegration = (integration) => {
    setSelectedIntegration(integration);
    setUserModalOpen(true);
  };

  const handleDeleteIntegration = async (integrationId) => {
    try {
      await api.delete(`/queueIntegration/${integrationId}`);
      toast.success(i18n.t("queueIntegration.toasts.deleted"));
    } catch (err) {
      toastError(err);
    }
    setDeletingUser(null);
    setSearchParam("");
    setPageNumber(1);
  };

  const loadMore = () => {
    setPageNumber((prevState) => prevState + 1);
  };

  const handleScroll = (e) => {
    if (!hasMore || loading) return;
    const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;
    if (scrollHeight - (scrollTop + 100) < clientHeight) {
      loadMore();
    }
  };

  // Função auxiliar para definir o ícone de acordo com o tipo
  const getIntegrationAvatar = (type) => {
    switch (type) {
      case "dialogflow":
        return dialogflow;
      case "n8n":
        return n8n;
      case "webhook":
        return webhooks;
      case "typebot":
        return typebot;
      default:
        return "";
    }
  };

  return (
    <MainContainer>
      {/* Modais de Confirmação e Integração */}
      <ConfirmationModal
        title={
          deletingUser &&
          `${i18n.t("queueIntegration.confirmationModal.deleteTitle")} ${deletingUser.name}?`
        }
        open={confirmModalOpen}
        onClose={() => setConfirmModalOpen(false)}
        onConfirm={() => handleDeleteIntegration(deletingUser.id)}
      />
      <IntegrationModal
        open={userModalOpen}
        onClose={handleCloseIntegrationModal}
        aria-labelledby="form-dialog-title"
        integrationId={selectedIntegration && selectedIntegration.id}
      />

      {/* Cabeçalho */}
      <MainHeader>
        <Box>
          <Typography
            variant="h5"
            sx={{
              color: "#6F2CC0",
              fontWeight: "bold",
              fontFamily: "Inter, sans-serif",
              marginBottom: 1,
            }}
          >
            {i18n.t("queueIntegration.title")}{" "}
            <Box component="span" sx={{ color: "#6F2CC0" }}>
              ({queueIntegration.length})
            </Box>
          </Typography>
          <Typography className={classes.headerSubtitle}>
  {i18n.t("Todas as ferramentas que você a sua equipe precisa, em um só lugar.")}
</Typography>
        </Box>

        <MainHeaderButtonsWrapper>
          <TextField
            placeholder={i18n.t("queueIntegration.searchPlaceholder")}
            type="search"
            value={searchParam}
            onChange={handleSearch}
            variant="outlined"
            size="small"
            sx={{
              backgroundColor: "#fff",
              borderRadius: 2,
              width: 220,
              marginRight: 2,
              "& .MuiOutlinedInput-root": {
                borderRadius: 2,
              },
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "#d8d8d8",
              },
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon style={{ color: "gray" }} />
                </InputAdornment>
              ),
            }}
          />
          <Button
            variant="contained"
            color="primary"
            onClick={handleOpenUserModal}
            sx={{
              backgroundColor: "#6F2CC0",
              color: "#fff",
              textTransform: "none",
              borderRadius: 2,
              fontWeight: "bold",
              fontFamily: "Inter, sans-serif",
              "&:hover": {
                backgroundColor: "#5a21a3",
              },
            }}
          >
            {i18n.t("queueIntegration.buttons.add")}
          </Button>
        </MainHeaderButtonsWrapper>
      </MainHeader>

      {/* GRID de Integrações (substituindo a Tabela) */}
      <Box
        sx={{
          border: "1px solid #E3E3E3",
          borderRadius: 2,
          marginTop: 2,
          padding: 2,
          backgroundColor: "#fff",
          boxShadow: "0 1px 1px rgba(0, 0, 0, 0.15)",
          overflowY: "auto",
          minHeight: 600,

          // Scrollbar customizada
          "&::-webkit-scrollbar": { width: "8px" },
          "&::-webkit-scrollbar-thumb": { backgroundColor: "#ccc", borderRadius: "4px" },
          "&::-webkit-scrollbar-track": { backgroundColor: "#f1f1f1" },
        }}
        onScroll={handleScroll}
      >
        {loading && <TableRowSkeleton columns={4} avatar />}

        <Grid container spacing={2}>
          {queueIntegration.map((integration) => (
            <Grid item xs={12} sm={6} md={4} lg={3} key={integration.id}>
              <Paper
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  borderRadius: 2,
                  border: "1px solid #ECECEC",
                  padding: 2,
                  minHeight: 150,
                  // ------------------------------
                  // TRANSIÇÃO e EFEITO de MOVIMENTO:
                  // ------------------------------
                  transition: "transform 0.2s, box-shadow 0.2s",
                  "&:hover": {
                    transform: "translateY(-4px)",
                    boxShadow: "0 4px 12px rgba(0,0,0,0.15)",
                  },
                }}
              >
                {/* Topo (Avatar e Nome) */}
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: 1,
                    marginBottom: 1,
                  }}
                >
                  <Avatar
                    src={getIntegrationAvatar(integration.type)}
                    sx={{
                      width: 48,
                      height: 48,
                      borderRadius: "8px",
                      border: "1px solid #E3E3E3",
                      objectFit: "contain",
                      bgcolor: "#fff",
                      // Se quiser efeito também no avatar ao passar o mouse, pode usar:
                      transition: "transform 0.2s",
                      "&:hover": {
                        transform: "scale(1.03)",
                      },
                    }}
                  />
                  <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <Typography sx={{ fontWeight: "bold" }}>
                      {integration.name}
                    </Typography>
                    <Typography variant="body2" sx={{ color: "#666" }}>
                      ID: {integration.id}
                    </Typography>
                  </Box>
                </Box>

                {/* Ações (Editar/Excluir) */}
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                    gap: 1,
                    marginTop: "auto",
                  }}
                >
                  <IconButton
                    size="small"
                    onClick={() => handleEditIntegration(integration)}
                    sx={{
                      backgroundColor: "transparent",
                      "&:hover": {
                        backgroundColor: "rgba(0,0,0,0.04)",
                        transform: "scale(1.1)", // Efeito rápido no ícone
                        transition: "all 0.2s",
                      },
                    }}
                  >
                    <FiEdit style={{ color: "#000" }} />
                  </IconButton>
                  <IconButton
                    size="small"
                    onClick={() => {
                      setConfirmModalOpen(true);
                      setDeletingUser(integration);
                    }}
                    sx={{
                      backgroundColor: "transparent",
                      "&:hover": {
                        backgroundColor: "rgba(0,0,0,0.04)",
                        transform: "scale(1.1)", // Efeito rápido no ícone
                        transition: "all 0.2s",
                      },
                    }}
                  >
                    <FiTrash style={{ color: "#E03D3D" }} />
                  </IconButton>
                </Box>
              </Paper>
            </Grid>
          ))}
        </Grid>
      </Box>
    </MainContainer>
  );
};

export default QueueIntegration;