// src/components/TagModal.js

import React, { useState, useEffect, useContext } from "react";

import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import toast from "../../errors/toast";

import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Colorize } from "@material-ui/icons";
import { ColorBox } from 'material-ui-color';

import { i18n } from "../../translate/i18n";

import api from "../../services/api";
import toastError from "../../errors/toastError";
import { AuthContext } from "../../context/Auth/AuthContext";
import { IconButton, InputAdornment } from "@material-ui/core";
import { FormControlLabel, Checkbox } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
	// Adicionando fonte Roboto ao título
	dialogTitle: {
		fontWeight: "500", // Aplicando espessura de 500
		fontSize: "1.2em",
		paddingBottom: "8px",
	},
	root: {
		display: "flex",
		flexWrap: "wrap",
	},
	outlinedInput: {
		borderRadius: "13px !important",  // Aplica bordas arredondadas com 13px
	},
	multFieldLine: {
		display: "flex",
		"& > *:not(:last-child)": {
			marginRight: theme.spacing(1),
		},
	},

	btnWrapper: {
		position: "relative",
	},

	cancelButton: {
		borderRadius: "8px", // Mantido
		color: theme.palette.secondary.main,
		borderRadius: "12px",  // Bordas arredondadas nos botões
		padding: "8px 16px",
		fontSize: "14px",
		fontWeight: "500",
		textTransform: "none",
	},

	buttonProgress: {
		color: green[500],
		position: "absolute",
		top: "50%",
		left: "50%",
		marginTop: -12,
		marginLeft: -12,
	},

	formControl: {
		margin: theme.spacing(1),
		minWidth: 120,
	},

	colorAdorment: {
		width: 20,
		height: 20,
		border: '1px solid #ccc',
		borderRadius: '50%',
	},

	// Adicionando o estilo para Paper/Modal com bordas arredondadas
	dialogPaper: {
		borderRadius: "12px", // Arredondando o Paper (Modal)
	},

	// Estilizando os botões com bordas arredondadas
	button: {
		borderRadius: "12px",  // Bordas arredondadas nos botões
		padding: "8px 16px",
		fontSize: "14px",
		fontWeight: "500",
		textTransform: "none",
	},

	// Estilizando o botão de "Excluir" com cor vermelha
	deleteButton: {
		backgroundColor: "#F44336", // Cor vermelha para exclusão
		color: "white",
	},
}));

const TagSchema = Yup.object().shape({
	name: Yup.string()
		.min(3, "Mensagem muito curta")
		.required("Obrigatório"),
	color: Yup.string()
		.matches(/^#([0-9A-F]{3}){1,2}$/i, "Cor inválida") // Adiciona validação para cor
		.required("Obrigatório")
});

const TagModal = ({ open, onClose, tagId, reload }) => {
	const classes = useStyles();
	const { user } = useContext(AuthContext);
	const [colorPickerModalOpen, setColorPickerModalOpen] = useState(false);

	const initialState = {
		name: "",
		color: "",
		kanban: 0
	};

	const [tag, setTag] = useState(initialState);

	useEffect(() => {
		if (!tagId) return;

		const fetchTag = async () => {
			try {
				const { data } = await api.get(`/tags/${tagId}`);
				setTag(prevState => ({ ...prevState, ...data }));
			} catch (err) {
				toastError(err);
			}
		};

		fetchTag();
	}, [tagId, open]);

	const handleClose = () => {
		setTag(initialState);
		setColorPickerModalOpen(false);
		onClose();
	};

	const handleSaveTag = async (values) => {
		const tagData = { ...values, userId: user.id };
		try {
			if (tagId) {
				await api.put(`/tags/${tagId}`, tagData);
			} else {
				await api.post("/tags", tagData);
			}
			toast.success(i18n.t("tagModal.success"));
			if (typeof reload === 'function') {
				reload();
			}
		} catch (err) {
			toastError(err);
		}
		handleClose();
	};

	return (
		<div className={classes.root}>
			<Dialog
				open={open}
				onClose={handleClose}
				maxWidth="xs"
				fullWidth
				scroll="paper"
				classes={{ paper: classes.dialogPaper }} // Aplicando bordas arredondadas no Paper
			>
				<DialogTitle id="form-dialog-title" className={classes.dialogTitle}>
					{(tagId ? `${i18n.t("tagModal.title.edit")}` : `${i18n.t("tagModal.title.add")}`)}
				</DialogTitle>
				<Formik
					initialValues={tag}
					enableReinitialize={true}
					validationSchema={TagSchema}
					onSubmit={(values, actions) => {
						handleSaveTag(values);
						actions.setSubmitting(false);
					}}
				>
					{({ touched, errors, isSubmitting, values, setFieldValue }) => (
						<Form>
							<DialogContent dividers>
								<div className={classes.multFieldLine}>
									<Field
										as={TextField}
										label={i18n.t("tagModal.form.name")}
										name="name"
										error={touched.name && Boolean(errors.name)}
										helperText={touched.name && errors.name}
										variant="outlined"
										margin="dense"
										fullWidth
										InputProps={{
											style: {
												borderRadius: "13px", // Garantindo que a borda interna também tenha o arredondamento
											},
										}}
									/>
								</div>
								<br />
								<div className={classes.multFieldLine}>
									<Field
										as={TextField}
										fullWidth
										label={i18n.t("tagModal.form.color")}
										name="color"
										id="color"
										error={touched.color && Boolean(errors.color)}
										helperText={touched.color && errors.color}
										InputProps={{
											classes: {
												root: classes.outlinedInput,  // Aplica bordas arredondadas
											},
											startAdornment: (
												<InputAdornment position="start">
													<div
														style={{ backgroundColor: values.color }}
														className={classes.colorAdorment}
													></div>
												</InputAdornment>
											),
											endAdornment: (
												<IconButton
													size="small"
													color="default"
													onClick={() => setColorPickerModalOpen(!colorPickerModalOpen)}
												>
													<Colorize />
												</IconButton>
											),
										}}
										variant="outlined"
										margin="dense"
									/>
								</div>
								{(user.profile === "admin" || user.profile === "supervisor") && (
									<>
										<div className={classes.multFieldLine}>
											<FormControlLabel
												control={
													<Checkbox
														checked={values.kanban === 1}
														onChange={(e) => setFieldValue('kanban', e.target.checked ? 1 : 0)}
														color="primary"
													/>
												}
												label="Quadro"
												labelPlacement="start"
											/>
										</div>
										<br />
									</>
								)}
								{colorPickerModalOpen && (
									<div>
										<ColorBox
											disableAlpha={true}
											hslGradient={false}
											style={{ margin: '20px auto 0' }}
											value={values.color}
											onChange={val => {
												setFieldValue('color', `#${val.hex}`);
											}}
										/>
									</div>
								)}
							</DialogContent>
							<DialogActions>

								<Button
									onClick={handleClose}
									color="secondary"
									disabled={isSubmitting}
									variant="outlined"
									className={classes.cancelButton}
									size="medium"
								>
									{i18n.t("tagModal.buttons.cancel")}
								</Button>
								<Button
									type="submit"
									color="primary"
									disabled={isSubmitting}
									variant="contained"
									className={classes.button}
									style={{
										backgroundColor: "#6f2cc0", // Cor para o botão de salvar
									}}
								>
									{tagId
										? `${i18n.t("tagModal.buttons.okEdit")}`
										: `${i18n.t("tagModal.buttons.okAdd")}`}
									{isSubmitting && (
										<CircularProgress
											size={24}
											className={classes.buttonProgress}
										/>
									)}
								</Button>
							</DialogActions>
						</Form>
					)}
				</Formik>
			</Dialog>
		</div>
	);
};

export default TagModal;