// src/pages/Announcements/Announcements.js

import React, { useState, useEffect, useReducer, useContext } from "react";
import toast from "../../errors/toast";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";

// Material UI v4
import {
  Box,
  Button,
  Grid,
  IconButton,
  InputAdornment,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography
} from "@material-ui/core";

// Importamos os ícones do React Icons
import { FiEdit, FiTrash } from "react-icons/fi";

import SearchIcon from "@material-ui/icons/Search";

import MainContainer from "../../components/MainContainer";
import MainHeader from "../../components/MainHeader";
import TableRowSkeleton from "../../components/TableRowSkeleton";
import AnnouncementModal from "../../components/AnnouncementModal";
import ConfirmationModal from "../../components/ConfirmationModal";
import { i18n } from "../../translate/i18n";
import toastError from "../../errors/toastError";
import api from "../../services/api";
import { SocketContext } from "../../context/Socket/SocketContext";
import { AuthContext } from "../../context/Auth/AuthContext";
import { isArray } from "lodash";

import MainHeaderButtonsWrapper from "../../components/MainHeaderButtonsWrapper";

// ----------------------------
// Estilos
// ----------------------------
const useStyles = makeStyles((theme) => ({
  // Barra superior, título e botão
  mainHeader: {
    marginBottom: theme.spacing(2),
  },
  headerSubtitle: {
    color: "#666",
    fontSize: "0.9rem",
    marginBottom: theme.spacing(2),
    fontFamily: "Inter, sans-serif",
  },
  title: {
    color: "#6F2CC0",
    fontWeight: "bold",
    fontFamily: "Inter, sans-serif",
    fontSize: "1.6rem",
    margin: 0,
  },
  headerButtonsBox: {
    display: "flex",
    justifyContent: "flex-end",
    gap: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      marginTop: theme.spacing(1),
    },
  },
  searchField: {
    "& .MuiOutlinedInput-root": {
      borderRadius: 8,
      backgroundColor: "#fff",
    },
    width: 220,
  },
  newButton: {
    backgroundColor: "#6F2CC0",
    color: "#fff",
    textTransform: "none",
    fontWeight: "bold",
    fontFamily: "Inter, sans-serif",
    borderRadius: 8,
    "&:hover": {
      backgroundColor: "#5a21a3",
    },
  },

  // Tabela e Paper
  tablePaper: {
    marginTop: theme.spacing(2),
    borderRadius: 8,
    border: "1px solid #E3E3E3",
    padding: theme.spacing(2),
    backgroundColor: "#fff",
    boxShadow: "0 1px 1px rgba(0,0,0,0.15)",
    overflowY: "auto",
    minHeight: 620,
    ...theme.scrollbarStyles,
  },
  // Aqui criamos uma classe específica para o Row do thead
  tableHeadRow: {
    backgroundColor: "#FAFAFA",
    "& th:first-child": {
      borderTopLeftRadius: 8,
    },
    "& th:last-child": {
      borderTopRightRadius: 8,
    },
  },
  tableHeadCell: {
    color: "#6B6B6B",
    fontWeight: "bold",
    borderBottom: "none",
    fontSize: "0.9rem",
    backgroundColor: "#F9F9F9",
    fontFamily: "Inter, sans-serif",
    textAlign: "center",
  },
  tableRow: {
    "&:not(:last-child)": {
      borderBottom: "1px solid #ECECEC",
    },
  },
  tableCellBody: {
    borderBottom: "1px solid #F0F0F0",
    fontSize: "0.875rem",
    fontFamily: "Inter, sans-serif",
    textAlign: "center",
    padding: theme.spacing(1, 2),
    "&:first-child": {
      borderRadius: "0 0 0 8px",
    },
    "&:last-child": {
      borderRadius: "0 0 8px 0",
    },
  },

  // Badges "Ativo"/"Inativo"
  badgeAtivo: {
    backgroundColor: "#DBFFE2",
    color: "#357A38",
    borderRadius: 12,
    padding: "2px 8px",
    fontSize: "0.75rem",
    fontWeight: 600,
    textTransform: "capitalize",
  },
  badgeInativo: {
    backgroundColor: "#FFE6E6",
    color: "#E03D3D",
    borderRadius: 12,
    padding: "2px 8px",
    fontSize: "0.75rem",
    fontWeight: 600,
    textTransform: "capitalize",
  },

  // Botões de ação
  actionButtons: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  actionButton: {
    margin: theme.spacing(0, 0.25),
    padding: "8px", // Ajuste o padding se necessário
    backgroundColor: "transparent", // Garante que não há fundo
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.04)", // Apenas leve destaque no hover
    },
  },
  editIcon: {
    color: "#000", // Preto para o ícone de edição
    fontSize: "18px", // Tamanho igual ao Quickemessages
  },
  deleteIcon: {
    color: "#E03D3D", // Vermelho para o ícone de remoção
    fontSize: "18px", // Tamanho igual ao Quickemessages
  },

  // Campo de busca
  searchInput: {
    backgroundColor: "#fff",
    borderRadius: 8,
    width: 220,
    marginRight: theme.spacing(2),
    "& .MuiOutlinedInput-root": {
      borderRadius: 8,
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#d8d8d8",
    },
  },
}));

// ----------------------------
// Reducer (Mantemos o mesmo)
// ----------------------------
const reducer = (state, action) => {
  if (action.type === "LOAD_ANNOUNCEMENTS") {
    const announcements = action.payload;
    const newAnnouncements = [];

    if (isArray(announcements)) {
      announcements.forEach((announcement) => {
        const announcementIndex = state.findIndex(
          (u) => u.id === announcement.id
        );
        if (announcementIndex !== -1) {
          state[announcementIndex] = announcement;
        } else {
          newAnnouncements.push(announcement);
        }
      });
    }
    return [...state, ...newAnnouncements];
  }

  if (action.type === "UPDATE_ANNOUNCEMENTS") {
    const announcement = action.payload;
    const announcementIndex = state.findIndex((u) => u.id === announcement.id);

    if (announcementIndex !== -1) {
      state[announcementIndex] = announcement;
      return [...state];
    } else {
      return [announcement, ...state];
    }
  }

  if (action.type === "DELETE_ANNOUNCEMENT") {
    const announcementId = action.payload;
    const announcementIndex = state.findIndex((u) => u.id === announcementId);
    if (announcementIndex !== -1) {
      state.splice(announcementIndex, 1);
    }
    return [...state];
  }

  if (action.type === "RESET") {
    return [];
  }

  return state;
};

const Announcements = () => {
  const classes = useStyles();
  const history = useHistory();
  const { user } = useContext(AuthContext);

  const [loading, setLoading] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [hasMore, setHasMore] = useState(false);
  const [selectedAnnouncement, setSelectedAnnouncement] = useState(null);
  const [deletingAnnouncement, setDeletingAnnouncement] = useState(null);
  const [announcementModalOpen, setAnnouncementModalOpen] = useState(false);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [searchParam, setSearchParam] = useState("");
  const [announcements, dispatch] = useReducer(reducer, []);
  const socketManager = useContext(SocketContext);

  // travar se user.super for false
  useEffect(() => {
    async function fetchData() {
      if (!user.super) {
        toast.error(
          "Esta empresa não possui permissão para acessar essa página! Estamos lhe redirecionando."
        );
        setTimeout(() => {
          history.push(`/`);
        }, 1000);
      }
    }
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Resetar a lista ao mudar o searchParam
  useEffect(() => {
    dispatch({ type: "RESET" });
    setPageNumber(1);
  }, [searchParam]);

  // Carregar anúncios (com debounce)
  useEffect(() => {
    setLoading(true);
    const delayDebounceFn = setTimeout(() => {
      fetchAnnouncements();
    }, 500);
    return () => clearTimeout(delayDebounceFn);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParam, pageNumber]);

  // Socket para atualizações em tempo real
  useEffect(() => {
    const companyId = user.companyId;
    const socket = socketManager.getSocket(companyId);

    socket.on(`company-announcement`, (data) => {
      if (data.action === "update" || data.action === "create") {
        dispatch({ type: "UPDATE_ANNOUNCEMENTS", payload: data.record });
      }
      if (data.action === "delete") {
        dispatch({ type: "DELETE_ANNOUNCEMENT", payload: +data.id });
      }
    });
    return () => {
      socket.disconnect();
    };
  }, [socketManager, user.companyId]);

  const fetchAnnouncements = async () => {
    try {
      const { data } = await api.get("/announcements/", {
        params: { searchParam, pageNumber },
      });
      dispatch({ type: "LOAD_ANNOUNCEMENTS", payload: data.records });
      setHasMore(data.hasMore);
      setLoading(false);
    } catch (err) {
      toastError(err);
    }
  };

  // Funções para abrir/fechar modais
  const handleOpenAnnouncementModal = () => {
    setSelectedAnnouncement(null);
    setAnnouncementModalOpen(true);
  };
  const handleCloseAnnouncementModal = () => {
    setSelectedAnnouncement(null);
    setAnnouncementModalOpen(false);
  };
  const handleSearch = (event) => {
    setSearchParam(event.target.value.toLowerCase());
  };

  // Editar
  const handleEditAnnouncement = (announcement) => {
    setSelectedAnnouncement(announcement);
    setAnnouncementModalOpen(true);
  };

  // Excluir
  const handleDeleteAnnouncement = async (announcement) => {
    try {
      if (announcement.mediaName)
        await api.delete(`/announcements/${announcement.id}/media-upload`);
      await api.delete(`/announcements/${announcement.id}`);

      toast.success(i18n.t("announcements.toasts.deleted"));
    } catch (err) {
      toastError(err);
    }
    setDeletingAnnouncement(null);
    setSearchParam("");
    setPageNumber(1);
  };

  // Paginação
  const loadMore = () => {
    setPageNumber((prev) => prev + 1);
  };
  const handleScroll = (e) => {
    if (!hasMore || loading) return;
    const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;
    if (scrollHeight - (scrollTop + 100) < clientHeight) {
      loadMore();
    }
  };

  // Converte numérico de prioridade em "Alta", "Média", "Baixa"
  const getPriorityBadge = (priority) => {
    if (priority === 1) {
      return <span className={classes.badgeAlta}>Alta</span>;
    } else if (priority === 2) {
      return <span className={classes.badgeMedia}>Média</span>;
    } else if (priority === 3) {
      return <span className={classes.badgeBaixa}>Baixa</span>;
    } else {
      return null;
    }
  };

  // Status: "Ativo" ou "Inativo"
  const getStatusBadge = (status) => {
    if (status) {
      return <span className={classes.badgeAtivo}>{i18n.t("announcements.active")}</span>;
    } else {
      return <span className={classes.badgeInativo}>{i18n.t("announcements.inactive")}</span>;
    }
  };

  return (
    <MainContainer>
      {/* Modais */}
      <ConfirmationModal
        title={
          deletingAnnouncement &&
          `${i18n.t("announcements.confirmationModal.deleteTitle")} ${
            deletingAnnouncement.name
          }?`
        }
        open={confirmModalOpen}
        onClose={setConfirmModalOpen}
        onConfirm={() => handleDeleteAnnouncement(deletingAnnouncement)}
      >
        {i18n.t("announcements.confirmationModal.deleteMessage")}
      </ConfirmationModal>
      <AnnouncementModal
        resetPagination={() => {
          setPageNumber(1);
          fetchAnnouncements();
        }}
        open={announcementModalOpen}
        onClose={handleCloseAnnouncementModal}
        aria-labelledby="form-dialog-title"
        announcementId={selectedAnnouncement && selectedAnnouncement.id}
      />

      {/* Barra superior com título, busca, e botão */}
      <MainHeader className={classes.mainHeader}>
        <Grid container alignItems="center" justifyContent="space-between">
          {/* Título e contagem */}
          <Grid item xs={12} sm={6}>
            <Typography variant="h5" className={classes.title}>
              {i18n.t("announcements.title")} ({announcements.length})
            </Typography>
            <Typography className={classes.headerSubtitle}>
  {i18n.t("O sistema passou por uma atualização? Crie um Novo Informativo e avise a todos!”")}
</Typography>
          </Grid>

          {/* Campo de busca + botão */}
          <Grid item xs={12} sm={6}>
            <Box className={classes.headerButtonsBox}>
              <TextField
                variant="outlined"
                size="small"
                placeholder={i18n.t("announcements.searchPlaceholder")}
                type="search"
                value={searchParam}
                onChange={handleSearch}
                className={classes.searchField}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon style={{ color: "gray" }} />
                    </InputAdornment>
                  ),
                }}
              />
              <Button
                variant="contained"
                className={classes.newButton}
                onClick={handleOpenAnnouncementModal}
              >
                + {i18n.t("announcements.buttons.add")}
              </Button>
            </Box>
          </Grid>
        </Grid>
      </MainHeader>

      {/* Tabela de informativos */}
      <Paper className={classes.tablePaper} onScroll={handleScroll}>
        <Table size="small" className={classes.tableRoot}>
          <TableHead>
            <TableRow className={classes.tableHeadRow}>
              <TableCell align="center" className={classes.tableHeadCell}>
                {i18n.t("announcements.table.title")}
              </TableCell>
              <TableCell align="center" className={classes.tableHeadCell}>
                {i18n.t("announcements.table.priority")}
              </TableCell>
              <TableCell align="center" className={classes.tableHeadCell}>
                {i18n.t("announcements.table.mediaName")}
              </TableCell>
              <TableCell align="center" className={classes.tableHeadCell}>
                {i18n.t("announcements.table.status")}
              </TableCell>
              <TableCell align="center" className={classes.tableHeadCell}>
                {i18n.t("announcements.table.actions")}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {announcements.map((announcement) => (
              <TableRow key={announcement.id} className={classes.tableRow}>
                <TableCell align="center" className={classes.tableCellBody}>
                  {announcement.title}
                </TableCell>

                <TableCell align="center" className={classes.tableCellBody}>
                  {getPriorityBadge(announcement.priority)}
                </TableCell>

                <TableCell align="center" className={classes.tableCellBody}>
                  {announcement.mediaName
                    ? announcement.mediaName
                    : i18n.t("quickMessages.noAttachment")}
                </TableCell>

                <TableCell align="center" className={classes.tableCellBody}>
                  {getStatusBadge(announcement.status)}
                </TableCell>

                <TableCell align="center" className={classes.tableCellBody}>
                  <IconButton
                    size="small"
                    onClick={() => handleEditAnnouncement(announcement)}
                    className={classes.actionButton}
                  >
                    <FiEdit className={classes.editIcon} />
                  </IconButton>
                  <IconButton
                    size="small"
                    onClick={() => {
                      setConfirmModalOpen(true);
                      setDeletingAnnouncement(announcement);
                    }}
                    className={classes.actionButton}
                  >
                    <FiTrash className={classes.deleteIcon} />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
            {loading && <TableRowSkeleton columns={5} />}
          </TableBody>
        </Table>
      </Paper>
    </MainContainer>
  );
};

export default Announcements;