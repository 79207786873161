import { toast } from "react-toastify"; // Importa o toast do react-toastify
import React from "react";
import "./toastStyles.css"; // Importa o arquivo de estilos personalizados

const toastInfo = (message) => {
  const toastOptions = {
    autoClose: 10000, // Tempo de exibição
    hideProgressBar: true, // Esconde a barra de progresso
    closeOnClick: true,
    pauseOnHover: false,
    draggable: true,
    progress: undefined,
    className: "custom-info-toast", // Classe CSS personalizada para o toast
    bodyClassName: "custom-info-toast-body", // Classe para o conteúdo do corpo do toast
  };

  // Define o layout do toast
  const InfoToast = (
    <div className="custom-toast-content">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth="1.5"
        stroke="currentColor"
        className="custom-toast-icon"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="m11.25 11.25.041-.02a.75.75 0 0 1 1.063.852l-.708 2.836a.75.75 0 0 0 1.063.853l.041-.021M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9-3.75h.008v.008H12V8.25Z"
        />
      </svg>
      <span>{message}</span>
    </div>
  );

  // Mostra o toast com a mensagem
  toast(InfoToast, toastOptions);
};

export default toastInfo; // Exporta para ser usado em outros arquivos