import React, { useState, useEffect, useCallback } from "react";
import toast from "../../errors/toast";
import {
  TextField,
  InputAdornment,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Avatar,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  Typography,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import InfoIcon from "@material-ui/icons/Info";
import api from "../../services/api";
import toastError from "../../errors/toastError";
import TableRowSkeleton from "../../components/TableRowSkeleton";
import { i18n } from "../../translate/i18n";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import MainContainer from "../../components/MainContainer";
import MainHeader from "../../components/MainHeader";
import MainHeaderButtonsWrapper from "../../components/MainHeaderButtonsWrapper";
import Title from "../../components/Title";
import ConfirmationModal from "../../components/ConfirmationModal";
import FileModal from "../../components/FileModal";

import { makeStyles } from "@material-ui/core/styles";

// Definição dos estilos utilizando makeStyles
const useStyles = makeStyles((theme) => ({
  // Cartão que envolve a tabela
  mainPaper: {
    border: "1px solid #E3E3E3",    // cor do contorno
    borderRadius: 8,
    marginTop: theme.spacing(2),
    padding: theme.spacing(2),
    backgroundColor: "#fff",
    boxShadow: "0 1px 1px rgba(0, 0, 0, 0.15)",
    overflowY: "auto",
    minHeight: 600,
    ...theme.scrollbarStyles
  },
    headerTitle: {
      color: "#6F2CC0",
      fontWeight: "bold",
      margin: 0
    },
  
    // (Se quiser um subtítulo, igual no Quickemessages)
    headerSubtitle: {
      color: "#666",
      fontSize: "0.9rem",
      marginBottom: theme.spacing(1),
      fontFamily: "Inter, sans-serif",
    },
      // Tabela
      tableRoot: {
        borderCollapse: "separate",
        borderSpacing: 0
      },
      // Arredondar cabeçalho (cantos superiores)
      tableHeadRow: {
        backgroundColor: "#FAFAFA",
        "& th:first-child": {
          borderTopLeftRadius: 8
        },
        "& th:last-child": {
          borderTopRightRadius: 8
        }
      },
      tableHeadCell: {
        color: "#6B6B6B",
        fontWeight: "bold",
        borderBottom: "none",
        fontSize: "0.9rem"
      },
      tableCell: {
        borderBottom: "1px solid #F0F0F0",
        fontSize: "0.875rem",
        "&:first-child": {
          borderRadius: "0 0 0 8px"
        },
        "&:last-child": {
          borderRadius: "0 0 8px 0"
        }
      },
    // Campo de busca
    searchInput: {
      backgroundColor: "#fff",
      borderRadius: 8,
      marginRight: theme.spacing(1), // Ajustado para um espaçamento menor
      flexGrow: 1, // Permite que o campo cresça conforme necessário
      "& .MuiOutlinedInput-root": {
        borderRadius: 8
      },
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "#d8d8d8"
      }
    },
  // Botão "Adicionar"
  addButton: {
    backgroundColor: "#6F2CC0",
    color: "#fff",
    textTransform: "none",
    borderRadius: 8,

    fontWeight: "bold",
    "&:hover": {
      backgroundColor: "#5a21a3"
    }
  },
}));

const UserRatings = () => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [userRatings, setUserRatings] = useState([]);
  const [searchParam, setSearchParam] = useState("");
  const [selectedFeedbacks, setSelectedFeedbacks] = useState([]); // Dados do modal
  const [modalOpen, setModalOpen] = useState(false); // Estado do modal

  // Função para buscar os feedbacks
  useEffect(() => {
    setLoading(true);
    const fetchUserRatings = async () => {
      try {
        const { data } = await api.get("/feedbacks/");
        setUserRatings(data);
      } catch (err) {
        toastError(err);
      } finally {
        setLoading(false);
      }
    };
    fetchUserRatings();
  }, []);

  // Função para filtrar os feedbacks com base no searchParam
  const handleSearchChange = (e) => {
    setSearchParam(e.target.value);
  };

  // Função para exportar feedbacks para PDF
  const exportToPDF = () => {
    const doc = new jsPDF();
    const columns = ["Atendente", "Total de Atendimentos", "Média de Classificação", "Feedbacks Positivos", "Feedbacks Negativos"];
    const rows = userRatings
      .filter((rating) =>
        rating.userName.toLowerCase().includes(searchParam.toLowerCase()) || rating.userId.toString().includes(searchParam)
      )
      .map((rating) => [
        rating.userName,
        rating.feedbackCount,
        rating.averageRate,
        rating.positiveFeedbackCount,
        rating.negativeFeedbackCount,
      ]);
    doc.autoTable(columns, rows);
    doc.save("feedbacks.pdf");
  };

  // Função para abrir o modal e buscar detalhes
  const handleActionClick = async (userId) => {
    setLoading(true);
    try {
      const { data } = await api.get(`/allfeedbacks/${userId}`); // Chama a nova API com o iduser
      setSelectedFeedbacks(data); // Armazena os dados no estado do modal
      setModalOpen(true); // Abre o modal
    } catch (err) {
      toastError(err);
    } finally {
      setLoading(false);
    }
  };

  // Fecha o modal
  const handleCloseModal = () => {
    setModalOpen(false);
  };

  return (
    <MainContainer>
      <Paper style={{ padding: "20px" }}>
        <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "20px" }}>
          <div>
            <Typography variant="h5" className={classes.headerTitle}>
              {i18n.t("Relatório de Avaliações")} ({userRatings.length})
            </Typography>
            <Typography className={classes.headerSubtitle}>
              {i18n.t(
                "Precisa de um relatório completo da sua equipe? Clique e exporte agora!"
              )}
            </Typography>
          </div>

          <TextField
            placeholder={i18n.t("contacts.searchPlaceholder")}
            type="search"
            value={searchParam}
            onChange={handleSearchChange}
            variant="outlined"
            size="small"
            className={classes.searchInput}

            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon style={{ color: "gray" }} />
                </InputAdornment>
              ),
            }}
          />
          <Button variant="contained" className={classes.addButton}
            onClick={exportToPDF}>
            {"Exportar PDF"}
          </Button>
        </div>

        <Table  size="small" className={classes.tableRoot}>
          <TableHead>
            <TableRow className={classes.tableHeadRow}>
              <TableCell className={classes.tableHeadCell}></TableCell>
              <TableCell  align="center" className={classes.tableHeadCell}>Atendente</TableCell>
              <TableCell  align="center" className={classes.tableHeadCell}>Total de Atendimentos</TableCell>
              <TableCell  align="center" className={classes.tableHeadCell}>Média de Classificação</TableCell>
              <TableCell  align="center" className={classes.tableHeadCell}>Feedbacks Positivos</TableCell>
              <TableCell  align="center" className={classes.tableHeadCell}>Feedbacks Negativos</TableCell>
              <TableCell  align="center" className={classes.tableHeadCell}>Ações</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {userRatings
              .filter((rating) =>
                rating.userName.toLowerCase().includes(searchParam.toLowerCase()) || rating.userId.toString().includes(searchParam)
              )
              .map((rating) => (
                <TableRow key={rating.id}>
                  <TableCell className={classes.tableCell}>
                    <Avatar src="/nopicture.png" />
                  </TableCell>
                  <TableCell align="center" className={classes.tableCell}>{rating.userName}</TableCell>
                  <TableCell align="center" className={classes.tableCell}>{rating.feedbackCount}</TableCell>
                  <TableCell align="center" className={classes.tableCell}>{rating.averageRate}</TableCell>
                  <TableCell align="center" className={classes.tableCell}>{rating.positiveFeedbackCount}</TableCell>
                  <TableCell align="center" className={classes.tableCell}>{rating.negativeFeedbackCount}</TableCell>
                  <TableCell className={classes.tableCell}>
                    <IconButton onClick={() => handleActionClick(rating.userId)}>
                      <InfoIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            {loading && <TableRowSkeleton />}
          </TableBody>
        </Table>
      </Paper>

      {/* Modal para exibir detalhes */}
      <Dialog open={modalOpen} onClose={handleCloseModal} maxWidth="sm" fullWidth>
        <DialogTitle>Detalhes dos Atendimentos</DialogTitle>
        <DialogContent>
          {selectedFeedbacks.length > 0 ? (
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell>Data</TableCell>
                  <TableCell>Avaliação</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {selectedFeedbacks.map((feedback) => (
                  <TableRow key={feedback.id}>
                    <TableCell>{feedback.id}</TableCell>
                    <TableCell>{new Date(feedback.createdAt).toLocaleString()}</TableCell>
                    <TableCell>{feedback.rate}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          ) : (
            <p>Nenhum atendimento encontrado.</p>
          )}
        </DialogContent>
      </Dialog>
    </MainContainer>
  );
};

export default UserRatings;
