// src/pages/Prompts/Prompts.js

import React, { useContext, useEffect, useReducer, useState, useCallback } from "react";
import {
  Button,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Backdrop,
  Fade,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { grey } from "@material-ui/core/colors"; // Importação adicionada
import { FiEdit, FiTrash } from "react-icons/fi"; // Importação dos ícones do react-icons

import toast from "../../errors/toast";

import MainContainer from "../../components/MainContainer";
import MainHeader from "../../components/MainHeader";
import MainHeaderButtonsWrapper from "../../components/MainHeaderButtonsWrapper";
import TableRowSkeleton from "../../components/TableRowSkeleton";
import PromptModal from "../../components/PromptModal";
import ConfirmationModal from "../../components/ConfirmationModal";
import { AuthContext } from "../../context/Auth/AuthContext";
import usePlans from "../../hooks/usePlans";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { SocketContext } from "../../context/Socket/SocketContext";
import { i18n } from "../../translate/i18n";
import toastError from "../../errors/toastError";
import api from "../../services/api";

// Estilos inspirados no layout da imagem e no QueueIntegration
const useStyles = makeStyles((theme) => ({
  // Container principal
  mainPaper: {
    flex: 1,
    padding: theme.spacing(2),
    overflowY: "auto",
    borderRadius: 8,
    border: "1px solid #E3E3E3",
    backgroundColor: "#fff",
    boxShadow: "0 1px 1px rgba(0,0,0,0.15)",
    ...theme.scrollbarStyles,
  },
  // Tabela
  tableHeadCell: {
    fontWeight: "bold",
    color: "#6B6B6B",
    textAlign: "center",
    backgroundColor: "#F9F9F9",
    fontFamily: "Inter, sans-serif",
    fontSize: "0.9rem",
    "&:first-child": {
      borderTopLeftRadius: 8, // Apenas no primeiro cabeçalho
    },
    "&:last-child": {
      borderTopRightRadius: 8, // Apenas no último cabeçalho
    },
    borderBottom: "2px solid #E3E3E3", // Borda inferior
  },
  // Células da Tabela
  tableCell: {
    textAlign: "center",
    color: "#333",
    fontSize: "0.9rem",
    padding: theme.spacing(1, 2),
    fontFamily: "Inter, sans-serif",
    borderBottom: "1px solid #E3E3E3", // Apenas a borda inferior
  },
  tableRow: {
    "&:last-child td": {
      borderBottom: 0, // Remove borda inferior do último item
    },
  },
  // Header Título e Subtítulo
  headerTitle: {
    color: "#6F2CC0",
    fontWeight: "bold",
    margin: 0,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.6rem",
  },
  headerSubtitle: {
    color: "#666",
    fontSize: "0.9rem",
    marginBottom: theme.spacing(2),
    fontFamily: "Inter, sans-serif",
  },
  // Botões
  button: {
    backgroundColor: "#6F2CC0", // Cor do fundo do botão
    color: "#fff", // Cor do texto
    textTransform: "none", // Não transforma o texto para maiúsculas
    borderRadius: 8, // Raio da borda
    fontWeight: "bold", // Peso da fonte
    fontFamily: "Inter, sans-serif",
    "&:hover": {
      backgroundColor: "#5a21a3", // Cor do fundo ao passar o mouse
    },
  },
  // Botões adicionais
  addButton: {
    backgroundColor: "#6F2CC0",
    color: "#fff",
    textTransform: "none",
    borderRadius: 8,
    fontWeight: "bold",
    fontFamily: "Inter, sans-serif",
    "&:hover": {
      backgroundColor: "#5a21a3",
    },
  },
  // Ícones
  editIcon: {
    color: "#000", // Preto para o ícone de edição
    fontSize: "18px", // Tamanho igual ao Connections
  },
  deleteIcon: {
    color: "#E03D3D", // Vermelho para o ícone de remoção
    fontSize: "18px", // Tamanho igual ao Connections
  },
  // Container dos botões de ação
  actionButtons: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  // Botão individual de ação
  actionButton: {
    margin: theme.spacing(0, 0.25),
    padding: "8px", // Ajuste o padding se necessário
    backgroundColor: "transparent", // Garante que não há fundo
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.04)", // Apenas leve destaque no hover
    },
  },
  // Estilos do modal
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "#E03D3D", // Vermelho para o ícone de remoção

  },
  modalContent: {
    backgroundColor: "#fff6f6",
    borderRadius: 8,
    padding: theme.spacing(3),
    outline: "none",
    boxShadow: theme.shadows[5],
    maxWidth: "600px",
    textAlign: "center",
    fontFamily: "Inter, sans-serif", // Fonte conforme Connections
  },
  modalTitle: {
    color: "#d9534f",
    fontWeight: "bold",
    marginBottom: theme.spacing(2),
    fontFamily: "Inter, sans-serif", // Fonte conforme Connections
  },
  modalLinks: {
    marginTop: theme.spacing(2),
    "& a": {
      color: "#6F2CC0",
      textDecoration: "none",
      "&:hover": {
        textDecoration: "underline",
      },
    },
    fontFamily: "Inter, sans-serif", // Fonte conforme Connections
  },
  closeButton: {
    marginTop: theme.spacing(3),
    backgroundColor: "#6F2CC0",
    color: "#fff",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#5a21a3",
    },
    fontFamily: "Inter, sans-serif", // Fonte conforme Connections
  },
}));

// Função reducer para gerenciar o estado dos prompts
const reducer = (state, action) => {
  switch (action.type) {
    case "LOAD_PROMPTS":
      const loadedPrompts = action.payload;
      const newPrompts = [];
      loadedPrompts.forEach((prompt) => {
        const promptIndex = state.findIndex((p) => p.id === prompt.id);
        if (promptIndex !== -1) {
          state[promptIndex] = prompt;
        } else {
          newPrompts.push(prompt);
        }
      });
      return [...state, ...newPrompts];

    case "UPDATE_PROMPTS":
      const updatedPrompt = action.payload;
      const updateIndex = state.findIndex((p) => p.id === updatedPrompt.id);
      if (updateIndex !== -1) {
        state[updateIndex] = updatedPrompt;
        return [...state];
      } else {
        return [updatedPrompt, ...state];
      }

    case "DELETE_PROMPT":
      const deleteId = action.payload;
      return state.filter((p) => p.id !== deleteId);

    case "RESET":
      return [];

    default:
      return state;
  }
};

const Prompts = () => {
  const classes = useStyles();

  const [prompts, dispatch] = useReducer(reducer, []);
  const [loading, setLoading] = useState(false);

  const [promptModalOpen, setPromptModalOpen] = useState(false);
  const [selectedPrompt, setSelectedPrompt] = useState(null);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(true); // Controle do modal de aviso

  const { user } = useContext(AuthContext);
  const { getPlanCompany } = usePlans();
  const history = useHistory();
  const companyId = user.companyId;
  const socketManager = useContext(SocketContext);

  // Checa permissão openAI e abre o modal de aviso
  useEffect(() => {
    async function fetchData() {
      try {
        const planConfigs = await getPlanCompany(undefined, companyId);
        if (!planConfigs.plan.useOpenAi) {
          // Abre o modal de aviso
          setModalOpen(true);
          toast.error(
            "Esta empresa não possui permissão para acessar essa página! Redirecionando..."
          );
          setTimeout(() => {
            history.push(`/`);
          }, 3000); // Aumentei o tempo para que o usuário possa ler o modal
        } else {
          // Se a permissão estiver correta, abrir o modal de aviso
          setModalOpen(true);
        }
      } catch (err) {
        toastError(err);
      }
    }
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Carrega prompts
  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        const { data } = await api.get("/prompt");
        dispatch({ type: "LOAD_PROMPTS", payload: data.prompts });
        setLoading(false);
      } catch (err) {
        toastError(err);
        setLoading(false);
      }
    })();
  }, []);

  // Socket
  useEffect(() => {
    const socket = socketManager.getSocket(companyId);

    socket.on(`company-${companyId}-prompt`, (data) => {
      if (data.action === "update" || data.action === "create") {
        dispatch({ type: "UPDATE_PROMPTS", payload: data.prompt });
      } else if (data.action === "delete") {
        dispatch({ type: "DELETE_PROMPT", payload: data.promptId });
      }
    });

    return () => {
      socket.disconnect();
    };
  }, [companyId, socketManager]);

  const handleOpenPromptModal = () => {
    setSelectedPrompt(null);
    setPromptModalOpen(true);
  };

  const handleClosePromptModal = () => {
    setSelectedPrompt(null);
    setPromptModalOpen(false);
  };

  const handleEditPrompt = (prompt) => {
    setSelectedPrompt(prompt); // Define o prompt selecionado
    setPromptModalOpen(true); // Abre o modal
  };

  const handleCloseConfirmationModal = () => {
    setConfirmModalOpen(false);
    setSelectedPrompt(null);
  };

  const handleDeletePrompt = async (promptId) => {
    try {
      const { data } = await api.delete(`/prompt/${promptId}`);
      toast.info(i18n.t(data.message));
      dispatch({ type: "DELETE_PROMPT", payload: promptId }); // Atualiza o estado local
    } catch (err) {
      toastError(err);
    }
    setSelectedPrompt(null);
  };

  return (
    <MainContainer>
      {/* Modal de Aviso Importante */}
      <Dialog
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        aria-labelledby="warning-dialog-title"
        className={classes.modal}
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        TransitionComponent={Fade}
      >
        <Fade in={modalOpen}>
          <div className={classes.modalContent}>
            <Typography variant="h6" className={classes.modalTitle}>
              Aviso Importante
            </Typography>
            <Typography variant="body1" gutterBottom>
              Se o OpenAI parou de funcionar no seu CRM WN, é porque o crédito grátis de
              $5 USD acabou. Recarregue a conta para continuar usando o serviço sem
              interrupções. Verifique se seu crédito expirou e recarregue, se necessário.
            </Typography>
            <Typography variant="body2" className={classes.modalLinks}>
              <strong>Links Úteis:</strong>
              <br />
              Uso:{" "}
              <a href="https://platform.openai.com/usage" target="_blank" rel="noreferrer">
                https://platform.openai.com/usage
              </a>
              <br />
              Fatura:{" "}
              <a
                href="https://platform.openai.com/account/billing/overview"
                target="_blank"
                rel="noreferrer"
              >
                https://platform.openai.com/account/billing/overview
              </a>
              <br />
              API:{" "}
              <a href="https://platform.openai.com/api-keys" target="_blank" rel="noreferrer">
                https://platform.openai.com/api-keys
              </a>
            </Typography>
            <Button
              onClick={() => setModalOpen(false)}
              className={classes.closeButton}
            >
              Fechar
            </Button>
          </div>
        </Fade>
      </Dialog>

      {/* Modais */}
      <ConfirmationModal
        title={
          selectedPrompt
            ? `${i18n.t("prompts.confirmationModal.deleteTitle")} ${selectedPrompt.name}?`
            : ""
        }
        open={confirmModalOpen}
        onClose={handleCloseConfirmationModal}
        onConfirm={() => handleDeletePrompt(selectedPrompt.id)}
      >
        {i18n.t("prompts.confirmationModal.deleteMessage")}
      </ConfirmationModal>

      <PromptModal
        open={promptModalOpen}
        onClose={handleClosePromptModal}
        promptId={selectedPrompt?.id}
      />

      {/* Header */}
      <MainHeader>
        <div>
          <Typography variant="h5" className={classes.headerTitle}>
            {i18n.t("prompts.title")}
          </Typography>
          <Typography className={classes.headerSubtitle}>
            {i18n.t("Seus prompts salvos em alguns cliques")}
          </Typography>
        </div>

        <MainHeaderButtonsWrapper>
          <Button
            variant="contained"
            onClick={handleOpenPromptModal}
            className={classes.button} // Aplica a classe com o estilo
          >
            {i18n.t("prompts.buttons.add")}
          </Button>
        </MainHeaderButtonsWrapper>
      </MainHeader>

      {/* Tabela */}
      <Paper className={classes.mainPaper}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell align="center" className={classes.tableHeadCell}>
                {i18n.t("prompts.table.name")}
              </TableCell>
              <TableCell align="center" className={classes.tableHeadCell}>
                {i18n.t("prompts.table.queue")}
              </TableCell>
              <TableCell align="center" className={classes.tableHeadCell}>
                {i18n.t("prompts.table.max_tokens")}
              </TableCell>
              <TableCell align="center" className={classes.tableHeadCell}>
                {i18n.t("prompts.table.actions")}
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {!loading &&
              prompts.map((prompt) => (
                <TableRow key={prompt.id}>
                  <TableCell align="center" className={classes.tableCell}>
                    {prompt.name}
                  </TableCell>
                  <TableCell align="center" className={classes.tableCell}>
                    {prompt.queue?.name}
                  </TableCell>
                  <TableCell align="center" className={classes.tableCell}>
                    {prompt.maxTokens}
                  </TableCell>
                  <TableCell align="center" className={classes.tableCell}>
                    <div className={classes.actionButtons}>
                      <IconButton
                        size="small"
                        onClick={() => handleEditPrompt(prompt)}
                        className={classes.actionButton}
                      >
                        <FiEdit className={classes.editIcon} />
                      </IconButton>
                      <IconButton
                        size="small"
                        onClick={() => {
                          setSelectedPrompt(prompt);
                          setConfirmModalOpen(true);
                        }}
                        className={classes.actionButton}
                      >
                        <FiTrash className={classes.deleteIcon} />
                      </IconButton>
                    </div>
                  </TableCell>
                </TableRow>
              ))}

            {loading && <TableRowSkeleton columns={4} />}

            {!loading && prompts.length === 0 && (
              <TableRow>
                <TableCell colSpan={4} align="center">
                  Nenhum prompt encontrado.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </Paper>
    </MainContainer>
  );
};

export default Prompts;