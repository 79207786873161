// src/components/Settings/Options.js

import React, { useEffect, useState } from "react";

import Grid from "@material-ui/core/Grid";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import FormHelperText from "@material-ui/core/FormHelperText";
import TextField from "@material-ui/core/TextField";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Box from "@material-ui/core/Box";

import useSettings from "../../hooks/useSettings";
import { toast } from 'react-toastify';
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { grey, blue } from "@material-ui/core/colors";
import OnlyForSuperUser from '../../components/OnlyForSuperUser';
import useAuth from '../../hooks/useAuth.js';

// Importação do CSS do react-toastify
import 'react-toastify/dist/ReactToastify.css';

// Estilização personalizada para o Switch no estilo iOS
const IOSSwitch = withStyles((theme) => ({
  root: {
    width: 42,
    height: 26,
    padding: 0,
    margin: theme.spacing(1),
  },
  switchBase: {
    padding: 1,
    '&$checked': {
      transform: 'translateX(16px)',
      color: theme.palette.common.white,
      '& + $track': {
        backgroundColor: theme.palette.primary.main,
        opacity: 1,
        border: 'none',
      },
    },
    '&$focusVisible $thumb': {
      color: theme.palette.primary.main,
      border: '6px solid #fff',
    },
  },
  thumb: {
    width: 24,
    height: 24,
    boxShadow: '0 2px 4px 0 rgba(0,35,11,0.2)',
  },
  outlinedInput: {
    borderRadius: "13px !important",  // Aplica bordas arredondadas com 13px
  },
  track: {
    borderRadius: 13,
    border: `1px solid ${theme.palette.grey[400]}`,
    backgroundColor: theme.palette.grey[50],
    opacity: 1,
    transition: theme.transitions.create(['background-color', 'border']),
  },
  checked: {},
  focusVisible: {},
}))(Switch);

// Estilização com makeStyles
const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(2), // Reduzido de (4,2) para (2)
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif', // Fonte consistente
    maxWidth: '1200px',
    margin: '0 auto',
  },
  section: {
    marginBottom: theme.spacing(2), // Reduzido de 4 para 2
    padding: theme.spacing(2), // Reduzido de 3 para 2
    backgroundColor: "#fff", // Definido para consistência
    borderRadius: 8, // Mantido
    boxShadow: "0 1px 3px rgba(0,0,0,0.1)", // Sombra mais sutil
    border: "1px solid #E3E3E3", // Mantido
  },
  sectionHeader: {
    marginBottom: theme.spacing(1), // Reduzido de 2 para 1
    color: "#6F2CC0", // Mesma cor do título no Quickemessages
    fontWeight: "bold",
    fontSize: "1.25rem", // Mantido para visibilidade
  },
  formControl: {
    width: "100%",
    marginBottom: theme.spacing(1), // Reduzido de 2 para 1
    borderRadius: 8,
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif', // Fonte consistente
  },
  selectContainer: {
    width: "100%",
    textAlign: "left",
  },
  switchLabel: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.spacing(0.5, 0), // Reduzido de 1,0 para 0.5,0
  },
  switchText: {
    flexGrow: 1,
    color: theme.palette.text.primary,
    fontSize: "1rem", // Consistente com os títulos
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  },
  // Novas classes para fontes consistentes
  headerTitle: {
    color: "#6F2CC0",
    fontWeight: "bold",
    margin: 0,
    fontSize: "1.5rem", // Similar ao Quickemessages
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  },
  subtitle: {
    margin: 0,
    color: "#8E8E8E",
    fontSize: "1rem", // Consistente com Quickemessages
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  },
  // Estilização para os TextFields e Selects
  textField: {
    '& .MuiOutlinedInput-root': {
      borderRadius: 8,
      fontSize: "1rem",
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    },
    '& .MuiInputLabel-outlined': {
      fontSize: "1rem",
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      color: "#6F2CC0",
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: "#d8d8d8",
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: "#6F2CC0",
    },
    '& .MuiOutlinedInput-input': {
      color: "#333",
    },
  },
  select: {
    '& .MuiOutlinedInput-root': {
      borderRadius: 8,
      fontSize: "1rem",
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    },
    '& .MuiInputLabel-outlined': {
      fontSize: "1rem",
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      color: "#6F2CC0",
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: "#d8d8d8",
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: "#6F2CC0",
    },
    '& .MuiSelect-select': {
      color: "#333",
      fontSize: "1rem",
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    },
  },
  // Estilização para os Buttons
  addButton: {
    backgroundColor: "#6F2CC0",
    color: "#fff",
    textTransform: "none",
    borderRadius: 8,
    fontWeight: "bold",
    fontSize: "1rem",
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    "&:hover": {
      backgroundColor: "#5a21a3"
    }
  },
  // Estilização para as Badges "Ativo"/"Inativo"
  badgeAtivo: {
    backgroundColor: "#DBFFE2",
    color: "#357A38",
    borderRadius: 12,
    padding: "2px 8px",
    fontSize: "0.875rem",
    fontWeight: 600,
    textTransform: "capitalize",
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  },
  badgeInativo: {
    backgroundColor: "#FFE6E6",
    color: "#E03D3D",
    borderRadius: 12,
    padding: "2px 8px",
    fontSize: "0.875rem",
    fontWeight: 600,
    textTransform: "capitalize",
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  }
}));

// Componente Reutilizável para Switch com estilo iOS
const SettingSwitch = ({ label, checked, onChange, loading }) => {
  const classes = useStyles(); // Adicionado para acessar classes dentro do SettingSwitch

  return (
    <div className={classes.switchLabel}>
      <Typography variant="body1" className={classes.switchText}>
        {label}
      </Typography>
      <IOSSwitch
        checked={checked}
        onChange={onChange}
        name={label}
        color="primary"
        disabled={loading}
      />
    </div>
  );
};

export default function Options(props) {
  const { settings, scheduleTypeChanged } = props;
  const classes = useStyles();

  const [currentUser, setCurrentUser] = useState({});
  const { getCurrentUserInfo } = useAuth();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function findData() {
      setLoading(true);
      try {
        const user = await getCurrentUserInfo();
        setCurrentUser(user);
      } catch (e) {
        toast.error(e.message || 'Erro ao buscar informações do usuário.');
      }
      setLoading(false);
    }
    findData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isSuper = () => {
    return currentUser.super;
  };

  // Estados para as configurações
  const [userRating, setUserRating] = useState("disabled");
  const [scheduleType, setScheduleType] = useState("disabled");
  const [callType, setCallType] = useState("enabled");
  const [chatbotType, setChatbotType] = useState("");
  const [CheckMsgIsGroup, setCheckMsgIsGroupType] = useState("enabled");
  const [FilaRodizio, setFilaRodizioType] = useState("disabled");
  const [rodizioTime, setrodizioTimeType] = useState("disabled");

  // Estados de carregamento
  const [loadingUserRating, setLoadingUserRating] = useState(false);
  const [loadingScheduleType, setLoadingScheduleType] = useState(false);
  const [loadingCallType, setLoadingCallType] = useState(false);
  const [loadingChatbotType, setLoadingChatbotType] = useState(false);
  const [loadingCheckMsgIsGroup, setLoadingCheckMsgIsGroup] = useState(false);
  const [loadingFilaRodizio, setloadingFilaRodizio] = useState(false);
  const [loadingRodizioTime, setloadingRodizioTime] = useState(false);

  // Outros estados omitidos para brevidade...
  const [ipixcType, setIpIxcType] = useState("");
  const [loadingIpIxcType, setLoadingIpIxcType] = useState(false);
  const [tokenixcType, setTokenIxcType] = useState("");
  const [loadingTokenIxcType, setLoadingTokenIxcType] = useState(false);

  const [ipmkauthType, setIpMkauthType] = useState("");
  const [loadingIpMkauthType, setLoadingIpMkauthType] = useState(false);
  const [clientidmkauthType, setClientIdMkauthType] = useState("");
  const [loadingClientIdMkauthType, setLoadingClientIdMkauthType] = useState(false);
  const [clientsecretmkauthType, setClientSecrectMkauthType] = useState("");
  const [loadingClientSecrectMkauthType, setLoadingClientSecrectMkauthType] = useState(false);

  const [asaasType, setAsaasType] = useState("");
  const [loadingAsaasType, setLoadingAsaasType] = useState(false);

  // Recursos a mais...
  const [trial, settrial] = useState('3');
  const [loadingtrial, setLoadingtrial] = useState(false);

  const [viewregister, setviewregister] = useState('disabled');
  const [loadingviewregister, setLoadingviewregister] = useState(false);

  const [allowregister, setallowregister] = useState('disabled');
  const [loadingallowregister, setLoadingallowregister] = useState(false);

  const [SendGreetingAccepted, setSendGreetingAccepted] = useState("disabled");
  const [loadingSendGreetingAccepted, setLoadingSendGreetingAccepted] = useState(false);

  const [SettingsTransfTicket, setSettingsTransfTicket] = useState("disabled");
  const [loadingSettingsTransfTicket, setLoadingSettingsTransfTicket] = useState(false);

  const [sendGreetingMessageOneQueues, setSendGreetingMessageOneQueues] = useState("disabled");
  const [loadingSendGreetingMessageOneQueues, setLoadingSendGreetingMessageOneQueues] = useState(false);

  const { update } = useSettings();

  useEffect(() => {
    if (Array.isArray(settings) && settings.length) {
      const userRatingSetting = settings.find((s) => s.key === "userRating");
      if (userRatingSetting) {
        setUserRating(userRatingSetting.value);
      }

      const FilaRodizioSetting = settings.find((s) => s.key === "FilaRodizio");
      if (FilaRodizioSetting) {
        setFilaRodizioType(FilaRodizioSetting.value);
      }
      const rodizioTimeSetting = settings.find((s) => s.key === "rodizioTime");
      if (rodizioTimeSetting) {
        setrodizioTimeType(rodizioTimeSetting.value);
      }
      const scheduleTypeSetting = settings.find((s) => s.key === "scheduleType");
      if (scheduleTypeSetting) {
        setScheduleType(scheduleTypeSetting.value);
      }
      const callTypeSetting = settings.find((s) => s.key === "call");
      if (callTypeSetting) {
        setCallType(callTypeSetting.value);
      }
      const CheckMsgIsGroupSetting = settings.find((s) => s.key === "CheckMsgIsGroup");
      if (CheckMsgIsGroupSetting) {
        setCheckMsgIsGroupType(CheckMsgIsGroupSetting.value);
      }

      const allowregisterSetting = settings.find((s) => s.key === 'allowregister');
      if (allowregisterSetting) {
        setallowregister(allowregisterSetting.value);
      }

      {/* PLW DESIGN SAUDAÇÃO */ }
      const SendGreetingAcceptedSetting = settings.find((s) => s.key === "sendGreetingAccepted");
      if (SendGreetingAcceptedSetting) {
        setSendGreetingAccepted(SendGreetingAcceptedSetting.value);
      }
      {/* PLW DESIGN SAUDAÇÃO */ }

      {/* TRANSFERIR TICKET */ }
      const SettingsTransfTicketSetting = settings.find((s) => s.key === "sendMsgTransfTicket");
      if (SettingsTransfTicketSetting) {
        setSettingsTransfTicket(SettingsTransfTicketSetting.value);
      }
      {/* TRANSFERIR TICKET */ }

      const viewregisterSetting = settings.find((s) => s.key === 'viewregister');
      if (viewregisterSetting) {
        setviewregister(viewregisterSetting.value);
      }

      const sendGreetingMessageOneQueuesSetting = settings.find((s) => s.key === "sendGreetingMessageOneQueues");
      if (sendGreetingMessageOneQueuesSetting) {
        setSendGreetingMessageOneQueues(sendGreetingMessageOneQueuesSetting.value)
      }

      const chatbotTypeSetting = settings.find((s) => s.key === "chatBotType");
      if (chatbotTypeSetting) {
        setChatbotType(chatbotTypeSetting.value);
      }

      const trialSetting = settings.find((s) => s.key === 'trial');
      if (trialSetting) {
        settrial(trialSetting.value);
      }

      const ipixcTypeSetting = settings.find((s) => s.key === "ipixc");
      if (ipixcTypeSetting) {
        setIpIxcType(ipixcTypeSetting.value);
      }

      const tokenixcTypeSetting = settings.find((s) => s.key === "tokenixc");
      if (tokenixcTypeSetting) {
        setTokenIxcType(tokenixcTypeSetting.value);
      }

      const ipmkauthTypeSetting = settings.find((s) => s.key === "ipmkauth");
      if (ipmkauthTypeSetting) {
        setIpMkauthType(ipmkauthTypeSetting.value);
      }

      const clientidmkauthTypeSetting = settings.find((s) => s.key === "clientidmkauth");
      if (clientidmkauthTypeSetting) {
        setClientIdMkauthType(clientidmkauthTypeSetting.value);
      }

      const clientsecretmkauthTypeSetting = settings.find((s) => s.key === "clientsecretmkauth");
      if (clientsecretmkauthTypeSetting) {
        setClientSecrectMkauthType(clientsecretmkauthTypeSetting.value);
      }

      const asaasTypeSetting = settings.find((s) => s.key === "asaas");
      if (asaasTypeSetting) {
        setAsaasType(asaasTypeSetting.value);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [settings]);

  // Handler genérico para Switches
  const handleSwitchChange = async (key, checked) => {
    const value = checked ? "enabled" : "disabled";
    // Atualiza o estado correspondente
    switch (key) {
      case "userRating":
        setUserRating(value);
        setLoadingUserRating(true);
        break;
      case "allowregister":
        setallowregister(value);
        setLoadingallowregister(true);
        break;
      case "viewregister":
        setviewregister(value);
        setLoadingviewregister(true);
        break;
      case "sendGreetingAccepted":
        setSendGreetingAccepted(value);
        setLoadingSendGreetingAccepted(true);
        break;
      case "sendMsgTransfTicket":
        setSettingsTransfTicket(value);
        setLoadingSettingsTransfTicket(true);
        break;
      case "sendGreetingMessageOneQueues":
        setSendGreetingMessageOneQueues(value);
        setLoadingSendGreetingMessageOneQueues(true);
        break;
      case "CheckMsgIsGroup":
        setCheckMsgIsGroupType(value);
        setLoadingCheckMsgIsGroup(true);
        break;
      case "FilaRodizio":
        setFilaRodizioType(value);
        setloadingFilaRodizio(true);
        break;
      case "call":
        setCallType(value);
        setLoadingCallType(true);
        break;
      default:
        return;
    }

    try {
      await update({ key, value });
      toast.success("Operação atualizada com sucesso.");
    } catch (error) {
      toast.error(error.message || "Erro ao atualizar a configuração.");
      // Reverter o estado em caso de erro
      switch (key) {
        case "userRating":
          setUserRating(prev => prev === "enabled" ? "disabled" : "enabled");
          break;
        case "allowregister":
          setallowregister(prev => prev === "enabled" ? "disabled" : "enabled");
          break;
        case "viewregister":
          setviewregister(prev => prev === "enabled" ? "disabled" : "enabled");
          break;
        case "sendGreetingAccepted":
          setSendGreetingAccepted(prev => prev === "enabled" ? "disabled" : "enabled");
          break;
        case "sendMsgTransfTicket":
          setSettingsTransfTicket(prev => prev === "enabled" ? "disabled" : "enabled");
          break;
        case "sendGreetingMessageOneQueues":
          setSendGreetingMessageOneQueues(prev => prev === "enabled" ? "disabled" : "enabled");
          break;
        case "CheckMsgIsGroup":
          setCheckMsgIsGroupType(prev => prev === "enabled" ? "disabled" : "enabled");
          break;
        case "call":
          setCallType(prev => prev === "enabled" ? "disabled" : "enabled");
          break;
        default:
          break;
      }
    } finally {
      // Desabilita o estado de carregamento
      switch (key) {
        case "userRating":
          setLoadingUserRating(false);
          break;
        case "allowregister":
          setLoadingallowregister(false);
          break;
        case "viewregister":
          setLoadingviewregister(false);
          break;
        case "sendGreetingAccepted":
          setLoadingSendGreetingAccepted(false);
          break;
        case "sendMsgTransfTicket":
          setLoadingSettingsTransfTicket(false);
          break;
        case "sendGreetingMessageOneQueues":
          setLoadingSendGreetingMessageOneQueues(false);
          break;
        case "CheckMsgIsGroup":
          setLoadingCheckMsgIsGroup(false);
          break;
        case "call":
          setLoadingCallType(false);
          break;
        default:
          break;
      }
    }
  };

  // Handlers para campos com Selects
  async function handleScheduleType(value) {
    setScheduleType(value);
    setLoadingScheduleType(true);
    try {
      await update({
        key: "scheduleType",
        value,
      });
      toast.success('Operação atualizada com sucesso.', {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        theme: "light",
      });
      if (typeof scheduleTypeChanged === "function") {
        scheduleTypeChanged(value);
      }
    } catch (error) {
      toast.error(error.message || "Erro ao atualizar a configuração.");
    } finally {
      setLoadingScheduleType(false);
    }
  }

  async function handleRodizioTime(value) {
    setrodizioTimeType(value);
    setloadingRodizioTime(true);
    try {
      await update({
        key: 'rodizioTime',
        value,
      });
      toast.success('Operação atualizada com sucesso.');
    } catch (error) {
      toast.error(error.message || "Erro ao atualizar a configuração.");
    } finally {
      setloadingRodizioTime(false);
    }
  }

  async function handleChatbotType(value) {
    setChatbotType(value);
    setLoadingChatbotType(true);
    try {
      await update({
        key: "chatBotType",
        value,
      });
      toast.success("Operação atualizada com sucesso.");
    } catch (error) {
      toast.error(error.message || "Erro ao atualizar a configuração.");
    } finally {
      setLoadingChatbotType(false);
    }
  }

  async function handletrial(value) {
    settrial(value);
    setLoadingtrial(true);
    try {
      await update({
        key: 'trial',
        value,
      });
      toast.success('Operação atualizada com sucesso.');
    } catch (error) {
      toast.error(error.message || "Erro ao atualizar a configuração.");
    } finally {
      setLoadingtrial(false);
    }
  }

  return (
    <>
      {/* Container Principal */}
      <Box className={classes.container}>
        { /* Seção de Configurações Gerais */}
        <Paper className={classes.section} elevation={3}>
          <Typography variant="h5" className={classes.sectionHeader}>
            Fila de Atendimento
          </Typography>
          <Divider style={{ marginBottom: 20 }} /> {/* Separador */}

          <Grid container spacing={2}> {/* Reduzido de spacing=3 para spacing=2 */}
            {/* Fila em Rodizio */}
            <Grid item xs={12} sm={6} md={4}>
              <SettingSwitch
                label="Fila em Rodizio"
                checked={FilaRodizio === "enabled"}
                onChange={(e, checked) => handleSwitchChange("FilaRodizio", checked)}
                loading={loadingFilaRodizio}
              />
            </Grid>

            {/* Tempo de Rodizio */}
            <Grid item xs={12} sm={6} md={4}>
              <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel id="rodizio-time-label">Tempo de Rodizio</InputLabel>
                <Select
                  labelId="rodizio-time-label"
                  value={rodizioTime}
                  onChange={(e) => handleRodizioTime(e.target.value)}
                  label="Tempo de Rodizio"
                  className={classes.select}
                >
                  <MenuItem value={"1"}>1 minuto</MenuItem>
                  <MenuItem value={"3"}>3 minutos</MenuItem>
                  <MenuItem value={"5"}>5 minutos</MenuItem>
                  <MenuItem value={"10"}>10 minutos</MenuItem>
                  <MenuItem value={"20"}>20 minutos</MenuItem>
                  <MenuItem value={"30"}>30 minutos</MenuItem>
                </Select>
                <FormHelperText>
                  {loadingRodizioTime && "Atualizando..."}
                </FormHelperText>
              </FormControl>
            </Grid>


          </Grid>
        </Paper>
        <Paper className={classes.section} elevation={3}>
          <Typography variant="h5" className={classes.sectionHeader}>
            Configurações Gerais
          </Typography>
          <Divider style={{ marginBottom: 20 }} /> {/* Separador */}

          <Grid container spacing={2}> {/* Reduzido de spacing=3 para spacing=2 */}
            {/* Avaliações */}
            <Grid item xs={12} sm={6} md={4}>
              <SettingSwitch
                label="Avaliações"
                checked={userRating === "enabled"}
                onChange={(e, checked) => handleSwitchChange("userRating", checked)}
                loading={loadingUserRating}
              />
            </Grid>

            {/* Gerenciamento de Expediente */}
            <Grid item xs={12} sm={6} md={4}>
              <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel id="schedule-type-label">Gerenciamento de Expediente</InputLabel>
                <Select
                  labelId="schedule-type-label"
                  value={scheduleType}
                  onChange={(e) => handleScheduleType(e.target.value)}
                  label="Gerenciamento de Expediente"
                  className={classes.select}
                >
                  <MenuItem value={"disabled"}>Desabilitado</MenuItem>
                  <MenuItem value={"queue"}>Fila</MenuItem>
                  <MenuItem value={"company"}>Empresa</MenuItem>
                </Select>
                <FormHelperText>
                  {loadingScheduleType && "Atualizando..."}
                </FormHelperText>
              </FormControl>
            </Grid>

            {/* Ignorar Mensagens de Grupos */}
            <Grid item xs={12} sm={6} md={4}>
              <SettingSwitch
                label="Ignorar Mensagens de Grupos"
                checked={CheckMsgIsGroup === "enabled"}
                onChange={(e, checked) => handleSwitchChange("CheckMsgIsGroup", checked)}
                loading={loadingCheckMsgIsGroup}
              />
            </Grid>

            {/* Aceitar Chamada */}
            <Grid item xs={12} sm={6} md={4}>
              <SettingSwitch
                label="Aceitar Chamada"
                checked={callType === "enabled"}
                onChange={(e, checked) => handleSwitchChange("call", checked)}
                loading={loadingCallType}
              />
            </Grid>

            {/* Tipo Chatbot */}
            <Grid item xs={12} sm={6} md={4}>
              <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel id="chatbot-type-label">Tipo Chatbot</InputLabel>
                <Select
                  labelId="chatbot-type-label"
                  value={chatbotType}
                  onChange={(e) => handleChatbotType(e.target.value)}
                  label="Tipo Chatbot"
                  className={classes.select}
                >
                  <MenuItem value={"text"}>Texto</MenuItem>
                  {/* <MenuItem value={"button"}>Botão</MenuItem> */}
                  {/* <MenuItem value={"list"}>Lista</MenuItem> */}
                </Select>
                <FormHelperText>
                  {loadingChatbotType && "Atualizando..."}
                </FormHelperText>
              </FormControl>
            </Grid>

            {/* Enviar Saudação ao Aceitar o Ticket */}
            <Grid item xs={12} sm={6} md={4}>
              <SettingSwitch
                label="Enviar saudação ao aceitar o ticket"
                checked={SendGreetingAccepted === "enabled"}
                onChange={(e, checked) => handleSwitchChange("sendGreetingAccepted", checked)}
                loading={loadingSendGreetingAccepted}
              />
            </Grid>

            {/* Enviar Mensagem de Transferência de Fila/Agente */}
            <Grid item xs={12} sm={6} md={4}>
              <SettingSwitch
                label="Enviar mensagem de transferência de Fila/agente"
                checked={SettingsTransfTicket === "enabled"}
                onChange={(e, checked) => handleSwitchChange("sendMsgTransfTicket", checked)}
                loading={loadingSettingsTransfTicket}
              />
            </Grid>

            {/* Enviar Saudação quando Houver Somente 1 Fila */}
            <Grid item xs={12} sm={6} md={4}>
              <SettingSwitch
                label="Enviar saudação quando houver somente 1 fila"
                checked={sendGreetingMessageOneQueues === "enabled"}
                onChange={(e, checked) => handleSwitchChange("sendGreetingMessageOneQueues", checked)}
                loading={loadingSendGreetingMessageOneQueues}
              />
            </Grid>
          </Grid>
        </Paper>

        {/* Configurações para Super Usuário */}
        <OnlyForSuperUser
          user={currentUser}
          yes={() => (
            <Paper className={classes.section} elevation={3}>
              <Typography variant="h5" className={classes.sectionHeader}>
                Configurações Super Usuário
              </Typography>
              <Divider style={{ marginBottom: 20 }} /> {/* Separador */}
              <Grid container spacing={2}> {/* Reduzido de spacing=3 para spacing=2 */}
                {/* Registro (Inscrição) Permitida */}
                <Grid item xs={12} sm={6} md={4}>
                  <SettingSwitch
                    label="Registro (Inscrição) Permitida?"
                    checked={allowregister === "enabled"}
                    onChange={(e, checked) => handleSwitchChange("allowregister", checked)}
                    loading={loadingallowregister}
                  />
                </Grid>

                {/* Registro (Inscrição) Visível */}
                <Grid item xs={12} sm={6} md={4}>
                  <SettingSwitch
                    label="Registro (Inscrição) Visível?"
                    checked={viewregister === "enabled"}
                    onChange={(e, checked) => handleSwitchChange("viewregister", checked)}
                    loading={loadingviewregister}
                  />
                </Grid>

                {/* Tempo de Trial */}
                <Grid item xs={12} sm={6} md={4}>
                  <FormControl variant="outlined" className={classes.formControl}>
                    <InputLabel id='trial-label'>Tempo de Trial?</InputLabel>
                    <Select
                      labelId='trial-label'
                      value={trial}
                      onChange={(e) => handletrial(e.target.value)}
                      label="Tempo de Trial?"
                      className={classes.select}
                    >
                      <MenuItem value={'1'}>1</MenuItem>
                      <MenuItem value={'2'}>2</MenuItem>
                      <MenuItem value={'3'}>3</MenuItem>
                      <MenuItem value={'4'}>4</MenuItem>
                      <MenuItem value={'5'}>5</MenuItem>
                      <MenuItem value={'6'}>6</MenuItem>
                      <MenuItem value={'7'}>7</MenuItem>
                    </Select>
                    <FormHelperText>
                      {loadingtrial && 'Atualizando...'}
                    </FormHelperText>
                  </FormControl>
                </Grid>
              </Grid>
            </Paper>
          )}
        />

        {/* Seção de Integrações */}
        <Paper className={classes.section} elevation={3}>
          <Typography variant="h5" className={classes.sectionHeader}>
            Integrações
          </Typography>
          <Divider style={{ marginBottom: 20 }} /> {/* Separador */}

          {/* Integração IXC */}
          <Box mb={2}> {/* Reduzido de mb={4} para mb={2} */}
            <Typography variant="h6" gutterBottom>
              IXC
            </Typography>
            <Grid container spacing={2}> {/* Reduzido de spacing=3 para spacing=2 */}
              <Grid item xs={12} sm={6} md={6}>
                <FormControl variant="outlined" className={classes.formControl}>
                  <TextField
                    id="ipixc"
                    name="ipixc"
                    margin="dense"
                    label="IP do IXC"
                    variant="outlined"
                    value={ipixcType}
                    onChange={async (e) => {
                      setIpIxcType(e.target.value);
                      setLoadingIpIxcType(true);
                      try {
                        await update({ key: "ipixc", value: e.target.value });
                        toast.success("IP do IXC atualizado com sucesso.");
                      } catch (error) {
                        toast.error(error.message || "Erro ao atualizar IP do IXC.");
                      } finally {
                        setLoadingIpIxcType(false);
                      }
                    }}
                    className={classes.textField}
                  />
                  <FormHelperText>
                    {loadingIpIxcType && "Atualizando..."}
                  </FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <FormControl variant="outlined" className={classes.formControl}>
                  <TextField
                    id="tokenixc"
                    name="tokenixc"
                    margin="dense"
                    label="Token do IXC"
                    variant="outlined"
                    value={tokenixcType}
                    onChange={async (e) => {
                      setTokenIxcType(e.target.value);
                      setLoadingTokenIxcType(true);
                      try {
                        await update({ key: "tokenixc", value: e.target.value });
                        toast.success("Token do IXC atualizado com sucesso.");
                      } catch (error) {
                        toast.error(error.message || "Erro ao atualizar Token do IXC.");
                      } finally {
                        setLoadingTokenIxcType(false);
                      }
                    }}
                    className={classes.textField}
                  />
                  <FormHelperText>
                    {loadingTokenIxcType && "Atualizando..."}
                  </FormHelperText>
                </FormControl>
              </Grid>
            </Grid>
          </Box>

          {/* Integração MK-AUTH */}
          <Box mb={2}> {/* Reduzido de mb={4} para mb={2} */}
            <Typography variant="h6" gutterBottom>
              MK-AUTH
            </Typography>
            <Grid container spacing={2}> {/* Reduzido de spacing=3 para spacing=2 */}
              <Grid item xs={12} sm={12} md={4}>
                <FormControl variant="outlined" className={classes.formControl}>
                  <TextField
                    id="ipmkauth"
                    name="ipmkauth"
                    margin="dense"
                    label="IP Mk-Auth"
                    variant="outlined"
                    value={ipmkauthType}
                    onChange={async (e) => {
                      setIpMkauthType(e.target.value);
                      setLoadingIpMkauthType(true);
                      try {
                        await update({ key: "ipmkauth", value: e.target.value });
                        toast.success("IP Mk-Auth atualizado com sucesso.");
                      } catch (error) {
                        toast.error(error.message || "Erro ao atualizar IP Mk-Auth.");
                      } finally {
                        setLoadingIpMkauthType(false);
                      }
                    }}
                    className={classes.textField}
                  />
                  <FormHelperText>
                    {loadingIpMkauthType && "Atualizando..."}
                  </FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12} md={4}>
                <FormControl variant="outlined" className={classes.formControl}>
                  <TextField
                    id="clientidmkauth"
                    name="clientidmkauth"
                    margin="dense"
                    label="Client Id"
                    variant="outlined"
                    value={clientidmkauthType}
                    onChange={async (e) => {
                      setClientIdMkauthType(e.target.value);
                      setLoadingClientIdMkauthType(true);
                      try {
                        await update({ key: "clientidmkauth", value: e.target.value });
                        toast.success("Client Id atualizado com sucesso.");
                      } catch (error) {
                        toast.error(error.message || "Erro ao atualizar Client Id.");
                      } finally {
                        setLoadingClientIdMkauthType(false);
                      }
                    }}
                    className={classes.textField}
                  />
                  <FormHelperText>
                    {loadingClientIdMkauthType && "Atualizando..."}
                  </FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12} md={4}>
                <FormControl variant="outlined" className={classes.formControl}>
                  <TextField
                    id="clientsecretmkauth"
                    name="clientsecretmkauth"
                    margin="dense"
                    label="Client Secret"
                    variant="outlined"
                    value={clientsecretmkauthType}
                    onChange={async (e) => {
                      setClientSecrectMkauthType(e.target.value);
                      setLoadingClientSecrectMkauthType(true);
                      try {
                        await update({ key: "clientsecretmkauth", value: e.target.value });
                        toast.success("Client Secret atualizado com sucesso.");
                      } catch (error) {
                        toast.error(error.message || "Erro ao atualizar Client Secret.");
                      } finally {
                        setLoadingClientSecrectMkauthType(false);
                      }
                    }}
                    className={classes.textField}
                  />
                  <FormHelperText>
                    {loadingClientSecrectMkauthType && "Atualizando..."}
                  </FormHelperText>
                </FormControl>
              </Grid>
            </Grid>
          </Box>

          {/* Integração ASAAS */}
          <Box mb={2}> {/* Reduzido de mb={4} para mb={2} */}
            <Typography variant="h6" gutterBottom>
              ASAAS
            </Typography>
            <Grid container spacing={2}> {/* Reduzido de spacing=3 para spacing=2 */}
              <Grid item xs={12} sm={12} md={12}>
                <FormControl variant="outlined" className={classes.formControl}>
                  <TextField
                    id="asaas"
                    name="asaas"
                    margin="dense"
                    label="Token Asaas"
                    variant="outlined"
                    value={asaasType}
                    onChange={async (e) => {
                      setAsaasType(e.target.value);
                      setLoadingAsaasType(true);
                      try {
                        await update({ key: "asaas", value: e.target.value });
                        toast.success("Token Asaas atualizado com sucesso.");
                      } catch (error) {
                        toast.error(error.message || "Erro ao atualizar Token Asaas.");
                      } finally {
                        setLoadingAsaasType(false);
                      }
                    }}
                    className={classes.textField}
                  />
                  <FormHelperText>
                    {loadingAsaasType && "Atualizando..."}
                  </FormHelperText>
                </FormControl>
              </Grid>
            </Grid>
          </Box>
        </Paper>
      </Box>
    </>
  );
}