import React, { useEffect, useState } from 'react';
import {
    Chart as ChartJS,
    ArcElement,
    Tooltip,
    Legend,
    Title,
} from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels'; // Import correto
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import brLocale from 'date-fns/locale/pt-BR';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { Button, Stack, TextField, Box } from '@mui/material';
import api from '../../services/api';
import { format } from 'date-fns';
import { toast } from 'react-toastify';
import './button.css'; // Assegure-se de que este CSS está correto

import { Typography } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles";

// Registro dos componentes necessários do ChartJS
ChartJS.register(
    ArcElement,
    Tooltip,
    Legend,
    Title,
    ChartDataLabels // Registrar o plugin corretamente
);

// Plugin para adicionar rótulo central
const centralLabelPlugin = {
    id: 'centralLabel',
    afterDraw: (chart) => {
        const { ctx, chartArea: { width, height } } = chart;

        // Calcula o total de atendimentos
        const total = chart.data.datasets[0].data.reduce((a, b) => a + b, 0);

        // Define as propriedades do rótulo
        ctx.save();
        const fontSize = Math.min(width, height) / 12; // Define o tamanho da fonte proporcionalmente
        ctx.font = `bold ${fontSize}px Inter, sans-serif`; // Aplica a fonte "Inter, sans-serif"
        ctx.fillStyle = '#000'; // Cor do texto
        ctx.textAlign = 'center';
        ctx.textBaseline = 'middle';

        // Posição central
        const centerX = width / 2;
        const centerY = height / 2;

    }
};

const useStyles = makeStyles((theme) => ({
    headerTitle: {
        color: "#00000",
        fontWeight: "bold",
        margin: 0,
        fontSize: "1.2rem"
    },
    filterContainer: {
        display: "flex",
        alignItems: "center",
        gap: "12px",
        flexWrap: "nowrap",
        marginBottom: theme.spacing(3),
        "& > *:not(:last-child)": {
            flex: 1,
        },
        "& > :last-child": {
            marginLeft: "auto",
        },
    },
    dateField: {
        "& .MuiOutlinedInput-root": {
            borderRadius: "8px",
            backgroundColor: "#FFFFFF",
            "& fieldset": {
                borderColor: "#E5E7EB",
            },
        },
        "& .MuiInputLabel-root": {
            color: "#6B7280",
            fontSize: "14px",
        },
        "& .MuiInputBase-input": {
            padding: "10px 14px",
            height: "20px",
            fontSize: "14px",
        },
    },
    filterButton: {
        backgroundColor: "#6F2CC0",
        color: "#FFFFFF",
        padding: "10px 24px",
        borderRadius: "8px",
        textTransform: "none",
        fontWeight: 600,
        fontSize: "14px",
        minWidth: "100px",
        height: "40px",
        marginLeft: "auto",
        "&:hover": {
            backgroundColor: "#5a23a0",
        },
    },
}));

// Definição das opções para o gráfico de rosca
export const options = {
    responsive: true,
    maintainAspectRatio: false,
    cutout: '60%', // Define o tamanho do buraco do donut
    plugins: {
        legend: {
            position: 'bottom',
            labels: {
                boxWidth: 20,
                padding: 15,
                font: {
                    family: 'Inter, sans-serif',
                    size: 14,
                },
            },
        },
        tooltip: {
            backgroundColor: '#FFFFFF', // Fundo branco
            borderColor: 'rgba(0, 0, 0, 0.1)', // Borda sutil
            borderWidth: 1, // Largura da borda
            titleColor: '#000', // Cor do título
            titleFont: {
                family: 'Inter, sans-serif',
                size: 14,
                weight: 'bold',
            },
            bodyColor: '#6F6F6F', // Cor do texto do corpo
            bodyFont: {
                family: 'Inter, sans-serif',
                size: 14,
            },
            cornerRadius: 12, // Bordas arredondadas
            padding: 10, // Espaçamento interno
            shadowOffsetX: 0, // Sombra horizontal
            shadowOffsetY: 4, // Sombra vertical
            shadowBlur: 10, // Intensidade do desfoque
            shadowColor: 'rgba(0, 0, 0, 0.1)', // Cor da sombra
            callbacks: {
                title: (context) => context[0].label, // Nome do segmento (label)
                label: (context) => {
                    const value = context.raw;
                    const total = context.chart.data.datasets[0].data.reduce((a, b) => a + b, 0);
                    const percentage = ((value / total) * 100).toFixed(1); // Calcula o percentual
                    return `${value} (${percentage}%)`; // Exibe valor e percentual
                },
            },
        },
        datalabels: {
            color: '#fff',
            formatter: (value) => value, // Mostra apenas o valor
            font: {
                family: 'Inter, sans-serif',
                weight: 'bold',
                size: 14,
            },
        },
    },
};

// Função para gerar cores aleatórias em formato HSL
const generatePastelColors = (num) => {
    const colors = [];
    for (let i = 0; i < num; i++) {
        const hue = Math.floor(Math.random() * 360); // Cor aleatória
        const saturation = 90 + Math.floor(Math.random() * 10); // Saturação entre 70-80%
        const lightness = 69 + Math.floor(Math.random() * 10); // Luminosidade entre 80-90%
        colors.push(`hsl(${hue}, ${saturation}%, ${lightness}%)`);
    }
    return colors;
};

// Componente ChatsUser
export const ChatsUser = () => {
    const [initialDate, setInitialDate] = useState(new Date());
    const [finalDate, setFinalDate] = useState(new Date());
    const [ticketsData, setTicketsData] = useState({ data: [], count: 0 });
    const classes = useStyles();

    const companyId = localStorage.getItem("companyId");

    useEffect(() => {
        // Definir data inicial como primeiro dia do mês atual
        const firstDayOfMonth = new Date();
        firstDayOfMonth.setDate(1);
        setInitialDate(firstDayOfMonth);
        
        // Definir data final como dia atual
        setFinalDate(new Date());
        
        handleGetTicketsInformation();
    }, []);

    const dataCharts = {
        labels: ticketsData.data.map((item) => item.nome),
        datasets: [
            {
                data: ticketsData.data.map((item) => item.quantidade),
                backgroundColor: generatePastelColors(ticketsData.data.length),
                                borderColor: '#fff', // Cor da borda entre os segmentos
                borderWidth: 2,
                borderRadius: 10, // Define o arredondamento das bordas dos segmentos
            },
        ],
    };

    const handleGetTicketsInformation = async () => {
        try {
            const formattedInitialDate = format(initialDate, 'yyyy-MM-dd');
            const formattedFinalDate = format(finalDate, 'yyyy-MM-dd');

            const { data } = await api.get(`/dashboard/ticketsUsers`, {
                params: {
                    initialDate: formattedInitialDate,
                    finalDate: formattedFinalDate,
                    companyId: companyId,
                }
            });
            
            // Ordenar por quantidade de atendimentos (decrescente)
            data.data = data.data.sort((a, b) => b.quantidade - a.quantidade);
            
            setTicketsData(data);
        } catch (error) {
            console.error(error);
            toast.error('Erro ao obter informações da conversa');
        }
    }

    return (
        <>
            <div className={classes.filterContainer}>
                <TextField
                    variant="outlined"
                    label="Início"
                    type="date"
                    value={initialDate.toISOString().split('T')[0]}
                    onChange={(e) => setInitialDate(new Date(e.target.value))}
                    className={classes.dateField}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
                <TextField
                    variant="outlined"
                    label="Fim"
                    type="date"
                    value={finalDate.toISOString().split('T')[0]}
                    onChange={(e) => setFinalDate(new Date(e.target.value))}
                    className={classes.dateField}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
                <Button
                    variant="contained"
                    className={classes.filterButton}
                    onClick={handleGetTicketsInformation}
                >
                    FILTRAR
                </Button>
            </div>

            <Box sx={{ width: '100%', height: '300px' }}>
                <Doughnut
                    data={dataCharts}
                    options={options}
                    plugins={[ChartDataLabels, centralLabelPlugin]}
                />
            </Box>
        </>
    );
}