import { toast } from "react-toastify";
import React from "react";
import "./toastStyles.css"; // Arquivo CSS para customização

const toastSuccess = (message) => {
  const toastOptions = {
    autoClose: 10000, // Tempo de exibição
    hideProgressBar: true, // Remove a barra de progresso
    closeOnClick: true,
    pauseOnHover: false,
    draggable: true,
    progress: undefined,
    className: "custom-toast", // Classe CSS personalizada
    bodyClassName: "custom-toast-body", // Classe para o conteúdo do corpo do toast
  };

  const SuccessToast = (
    <div className="custom-toast-content">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="custom-toast-icon"
        fill="none"
        viewBox="0 0 24 24"
      >
        <path
          stroke="currentColor"
          strokeWidth="2"
          d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
        />
      </svg>
      <span>{message}</span>
    </div>
  );

  toast(SuccessToast, toastOptions);
};

export default toastSuccess;