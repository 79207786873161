import React, { useState, useEffect, useContext } from "react";
import { Link as RouterLink } from "react-router-dom";
import {
  Button,
  CssBaseline,
  TextField,
  Link,
  Typography,
  Container,
  InputAdornment,
  IconButton,
  Box,
  Grid
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Lock, LockOpen, ArrowForward } from "@material-ui/icons";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import "./style.css"; // Seu CSS com .container-right, etc.
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import { AuthContext } from "../../context/Auth/AuthContext";
import { i18n } from "../../translate/i18n";

// Imagens
import logo from "../../assets/logo.png";
import bgLogin from "../../assets/bg-login.png";

// URL e Master Key do jsonbin.io
// const JSONBIN_URL = "https://api.jsonbin.io/v3/b/67cf64848561e97a50e998c1";
// const JSONBIN_MASTER_KEY = "$2a$10$iYp1MvSrLWtMCqvVefaGW.UP.Z2DXVruNImnnSNGSPt1XMJnJXAoO";

// URL do arquivo JSON hospedado no GitHub Gist
const GIST_URL = "https://gist.githubusercontent.com/luis-heinriq13/ab454b9656bf4097da22a13bbd033616/raw/560f74af5030fea9173259f3cfdcde91418034ff/testimonials.json";


const useStyles = makeStyles((theme) => ({
  geral: {
    display: "flex",
    width: "100vw",
    height: "100vh",
    overflow: "hidden",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
    },
  },

  leftContainer: {
    position: "relative",
    width: "50%",
    backgroundColor: "#0C0C0C",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    overflow: "hidden",
    [theme.breakpoints.down("md")]: {
      display: "none", // Esconde em telas < 960px
    },
  },

  rightContainer: {
    position: "relative",
    width: "50%",
    backgroundImage: `url(${bgLogin})`,
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.down("md")]: {
      width: "100%",   // O lado direito passa a ocupar toda a largura
      height: "100vh", // E toda a altura, se desejar
    },
  },
  purpleBlur1: {
    position: "absolute",
    width: 200,
    height: 200,
    borderRadius: "50%",
    background: "#6F2CC0",
    filter: "blur(80px)",
    top: "10%",
    left: "30%",
    opacity: 0.4,
    zIndex: 1,
  },
  purpleBlur2: {
    position: "absolute",
    width: 150,
    height: 150,
    borderRadius: "50%",
    background: "#a201ff",
    filter: "blur(60px)",
    bottom: "15%",
    right: "20%",
    opacity: 0.3,
    zIndex: 1,
  },
  logoLeft: {
    zIndex: 2,
    width: 390,
    marginBottom: theme.spacing(2),
  },

  /**
   * Card fixo para depoimentos, com tamanho fixo para evitar mudanças de layout.
   */
  testimonialCard: {
    zIndex: 2,
    backgroundColor: "rgba(255, 255, 255, 0.05)",
    backdropFilter: "blur(4px)",
    borderRadius: 12,
    padding: theme.spacing(2),
    maxWidth: 450,
    width: "100%",
    textAlign: "left",
    color: "#FFF",
    marginBottom: theme.spacing(4),
    minHeight: 120,
  },

  /**
   * Container interno para o conteúdo que faz fade in/out.
   */
  testimonialContent: {
    transition: "opacity 0.7s ease",
    width: "100%",
  },
  testimonialText: {
    marginBottom: theme.spacing(2),
    fontSize: "0.9rem",
    lineHeight: 1.4,
  },
  testimonialAuthor: {
    display: "flex",
    alignItems: "center",
  },
  authorAvatar: {
    width: 32,
    height: 32,
    borderRadius: "50%",
    marginRight: theme.spacing(1),
    objectFit: "cover",
  },
  authorInfo: {
    fontSize: "0.8rem",
    lineHeight: 1.2,
  },

  footerLeft: {
    position: "absolute",
    bottom: theme.spacing(2),
    display: "flex",
    width: "100%",
    justifyContent: "center",
    color: "#666",
    fontSize: "0.8rem",
    zIndex: 2,
  },

  formContainer: {
    backgroundColor: "#fff",
    borderRadius: 20,
    boxShadow: "0 4px 20px rgba(0, 0, 0, 0.15)",
    maxWidth: 420,
    width: "90%",
    padding: theme.spacing(4, 3),
    textAlign: "center",
    zIndex: 2,
    [theme.breakpoints.down("xs")]: {
      padding: theme.spacing(3, 2),
      width: "85%",
    },
  },
  mainTitle: {
    fontWeight: 600,
    fontSize: "1.4rem",
    color: "#a201ff",
    marginBottom: theme.spacing(1),
  },
  subTitle: {
    fontSize: "0.95rem",
    color: "#525252",
    marginBottom: theme.spacing(3),
  },
  inputField: {
    marginBottom: theme.spacing(2),
    "& .MuiOutlinedInput-root": {
      borderRadius: 12,
    },
  },
  submitButton: {
    marginTop: theme.spacing(2),
    borderRadius: 12,
    fontWeight: 600,
    fontSize: "0.95rem",
    height: 43,
    textTransform: "uppercase",
    background: "linear-gradient(to right, #a201ff, #B859DD)",
    color: "#fff",
    transition: "transform 0.3s ease, background 0.3s ease",
    "&:hover": {
      transform: "scale(1.03)",
      background: "linear-gradient(to right, #B859DD, #a201ff)",
    },
  },
  footerText: {
    marginTop: theme.spacing(3),
    fontSize: "0.9rem",
    color: "#666",
  },
  linkRoxo: {
    color: "#a201ff",
    textDecoration: "none",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  termsText: {
    marginTop: theme.spacing(2),
    fontSize: "0.8rem",
    color: "#666",
    lineHeight: 1.3,
  },
  iconButton: {
    padding: theme.spacing(1),
    marginRight: theme.spacing(2),
  },
  whatsappButton: {
    position: "fixed",
    bottom: 20,
    right: 20,
    width: 60,
    height: 60,
    borderRadius: "50%",
    backgroundColor: "#25D366",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "#fff",
    textDecoration: "none",
    boxShadow: "0 2px 5px rgba(0,0,0,0.3)",
    zIndex: 9999,
    transition: "background-color 0.2s ease",
    "&:hover": {
      backgroundColor: "#20b955",
    },
    [theme.breakpoints.down("xs")]: {
      width: 50,
      height: 50,
      bottom: 15,
      right: 15,
    },
  },
  lockIconContainer: {
    position: "relative",
    width: 24,
    height: 24,
    marginLeft: theme.spacing(1),
  },
  visibleIcon: {
    opacity: 1,
    transition: "opacity 0.3s ease",
  },
  hiddenIcon: {
    opacity: 0,
    transition: "opacity 0.3s ease",
    position: "absolute",
    top: 0,
    left: 0,
  },
}));

const Login = () => {
  const classes = useStyles();
  const { handleLogin } = useContext(AuthContext);

  // Estado do formulário
  const [user, setUser] = useState({ email: "", password: "" });
  const [showPassword, setShowPassword] = useState(false);

  // Depoimentos vindos do GitHub Gist (inicia como array vazio)
  const [testimonials, setTestimonials] = useState([]);
  // Índice do depoimento atual
  const [index, setIndex] = useState(0);
  // Controle do fade para o conteúdo interno
  const [fade, setFade] = useState(true);

  // Buscar os depoimentos do Gist
  useEffect(() => {
    async function fetchTestimonials() {
      try {
        const response = await fetch(GIST_URL);
        const jsonData = await response.json();
        // jsonData deve ser um array com os depoimentos
        setTestimonials(jsonData);
      } catch (error) {
        console.error("Erro ao buscar depoimentos do Gist:", error);
      }
    }
    fetchTestimonials();
  }, []);


  // Ciclo de troca do conteúdo interno (fade)
  useEffect(() => {
    if (!testimonials.length) return;
    const intervalId = setInterval(() => {
      setFade(false);
      setTimeout(() => {
        setIndex((prev) => (prev + 1) % testimonials.length);
        setFade(true);
      }, 700);
    }, 5000);
    return () => clearInterval(intervalId);
  }, [testimonials]);

  // Se os depoimentos ainda não foram carregados, usa objeto vazio
  const current = testimonials[index] || { text: "", author: "", title: "", avatar: "" };

  const handleChangeInput = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value });
  };
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    const trimmedUser = { ...user, email: user.email.trim() };
    handleLogin(trimmedUser);
  };

  // Link do WhatsApp
  const whatsappLink = "https://wa.me/5584998400790?text=Ol%C3%A1%21%20Gostaria%20de%20saber%20mais%20sobre%20os%20planos.";

  return (
    <div className={classes.geral}>
      <CssBaseline />

      {/* Lado Esquerdo */}
      <div className={classes.leftContainer}>
        <div className={classes.purpleBlur1} />
        <div className={classes.purpleBlur2} />

        <img src={logo} alt="WhatsNeed Logo" className={classes.logoLeft} />

        {/* Card fixo para depoimentos */}
        <div className={classes.testimonialCard}>
          <div
            className={classes.testimonialContent}
            style={{ opacity: fade ? 1 : 0 }}
          >
            <p className={classes.testimonialText}>{current.text}</p>
            <div className={classes.testimonialAuthor}>
              <img
                src={current.avatar}
                alt="Autor"
                className={classes.authorAvatar}
              />
              <div className={classes.authorInfo}>
                <strong>{current.author}</strong>
                <br />
                {current.title}
              </div>
            </div>
          </div>
        </div>

        <div className={classes.footerLeft}>
          <span>© WhatsNeed 2024</span>
        </div>
      </div>

      {/* Lado Direito */}
      <div className={classes.rightContainer}>
        <Container maxWidth="xs" className={classes.formContainer}>
          <Typography className={classes.mainTitle}>Bem-vindo de volta!</Typography>
          <Typography className={classes.subTitle}>
            Entre na sua conta para acessar o WhatsNeed CRM
          </Typography>

          <form noValidate onSubmit={handleSubmit}>
            <TextField
              variant="outlined"
              required
              fullWidth
              label="Email"
              placeholder="seu@email.com"
              name="email"
              autoComplete="email"
              value={user.email}
              onChange={handleChangeInput}
              className={classes.inputField}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <MailOutlineIcon style={{ color: "#999" }} />
                  </InputAdornment>
                )
              }}
            />

            <TextField
              variant="outlined"
              required
              fullWidth
              label="Senha"
              placeholder="********"
              name="password"
              type={showPassword ? "text" : "password"}
              value={user.password}
              onChange={handleChangeInput}
              className={classes.inputField}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <IconButton 
                      onClick={handleClickShowPassword} 
                      edge="start" 
                      className={classes.iconButton}
                    >
                      <span className={classes.lockIconContainer}>
                        <Lock className={showPassword ? classes.hiddenIcon : classes.visibleIcon} />
                        <LockOpen className={showPassword ? classes.visibleIcon : classes.hiddenIcon} />
                      </span>
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />

            <div style={{ marginBottom: 16, textAlign: "right" }}>
              <Link
                component={RouterLink}
                to="/forgetpsw"
                style={{ fontSize: "0.85rem", color: "#a201ff" }}
              >
                Esqueceu sua senha?
              </Link>
            </div>

            <Button
              type="submit"
              fullWidth
              variant="contained"
              className={classes.submitButton}
              startIcon={<ArrowForward />}
            >
              Entrar
            </Button>
          </form>

          <Typography className={classes.footerText}>
            Ainda não tem uma conta?{" "}
            <Link
              component={RouterLink}
              to="/signup"
              className={classes.linkRoxo}
            >
              Cadastre-se!
            </Link>
          </Typography>

          <Typography className={classes.termsText}>
            Ao prosseguir, você concorda com nossos{" "}
            <a
              className={classes.linkRoxo}
              href="https://app.titan.email/mail/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Termos de Serviço
            </a>{" "}
            e{" "}
            <a
              className={classes.linkRoxo}
              href="https://app.titan.email/mail/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Política de Privacidade
            </a>
          </Typography>

          <Box mt={2} />
        </Container>
      </div>

      {/* Botão do WhatsApp */}
      <a
        href={whatsappLink}
        target="_blank"
        rel="noopener noreferrer"
        className={classes.whatsappButton}
      >
        <WhatsAppIcon style={{ fontSize: 28 }} />
      </a>
    </div>
  );
};

export default Login;