import React, { useState, useContext, useEffect } from "react";
import clsx from "clsx";
import moment from "moment";
import {
  makeStyles,
  Drawer,
  AppBar,
  Toolbar,
  List,
  Typography,
  Divider,
  Tooltip,
  MenuItem,
  IconButton,
  Menu,
  useTheme,
  useMediaQuery,
} from "@material-ui/core";

import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';

import MenuIcon from "@material-ui/icons/Menu";
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import AccountCircle from "@material-ui/icons/AccountCircle";
import CachedIcon from "@material-ui/icons/Cached";

import MainListItems from "./MainListItems";
import VolumePopover from "../components/VolumePopover";
//import NotificationsVolume from "../components/VolumePopover";
import NotificationsPopOver from "../components/NotificationsPopOver";
import UserModal from "../components/UserModal";
import { AuthContext } from "../context/Auth/AuthContext";
import BackdropLoading from "../components/BackdropLoading";
import DarkMode from "../components/DarkMode";
import { i18n } from "../translate/i18n";
import toastError from "../errors/toastError";
import AnnouncementsPopover from "../components/AnnouncementsPopover";

import EventAvailableIcon from '@mui/icons-material/EventAvailable';

// Importação de novos ícones
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import ForumIcon from "@mui/icons-material/Forum";
import MarkChatUnreadIcon from "@mui/icons-material/MarkChatUnread";
import LogoutIcon from '@mui/icons-material/Logout';

import { SocketContext } from "../context/Socket/SocketContext";
import ChatPopover from "../pages/Chat/ChatPopover";

import { useDate } from "../hooks/useDate";

import ColorModeContext from "../layout/themeContext";
import Brightness4Icon from '@material-ui/icons/Brightness4';
import Brightness7Icon from '@material-ui/icons/Brightness7';

import { useLocation } from "react-router-dom";


const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    height: "100vh",
    [theme.breakpoints.down("sm")]: {
      height: "calc(100vh - 56px)",
    },
    backgroundColor: theme.palette.fancyBackground,
    '& .MuiButton-outlinedPrimary': {
      color: theme.mode === 'light' ? '#FFF' : '#FFF',
      backgroundColor: theme.mode === 'light' ? '#6F2CC0' : '#1c1c1c',
      //border: theme.mode === 'light' ? '1px solid rgba(0 124 102)' : '1px solid rgba(255, 255, 255, 0.5)',
    },
    '& .MuiTab-textColorPrimary.Mui-selected': {
      color: theme.mode === 'light' ? '#6F2CC0' : '#FFF',
    }
  },
  avatar: {
    width: "100%",
  },
  toolbar: {
    paddingLeft: 7,
    paddingRight: 24, // keep right padding when drawer closed
    color: theme.palette.dark.main,
    background: theme.palette.barraSuperior,
    overflow: "hidden",
  },
  toolbarIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "0 8px",
    minHeight: "48px",
    [theme.breakpoints.down("sm")]: {
      height: "48px"
    }
  },
  appBar: {
    padding: "0px !important",
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    background: theme.palette.barraSuperior, // Adicionado para manter a cor consistente
    color: "#000", // Texto/ícones pretos
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    })
  },
  appBarShift: {
    marginLeft: drawerWidth,
    //  width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: "none",
  },
  title: {
    flexGrow: 1,
    fontSize: 14,
    color: "white",
  },
  drawerPaper: {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    [theme.breakpoints.down("sm")]: {
      width: "100%"
    },
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    ...theme.scrollbarStylesSoft
  },
  drawerPaperClose: {
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9),
    },
    [theme.breakpoints.down("sm")]: {
      width: "0%"
    }
  },
  appBarSpacer: {
    minHeight: "48px",
  },
  content: {
    flex: 1,
    overflow: "auto",
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column"
  },
  containerWithScroll: {
    flex: 1,
    padding: theme.spacing(1),
    ...theme.scrollbarStyles,
  },
  NotificationsPopOver: {
    // color: theme.barraSuperior.secondary.main,
  },
  logo: {
    width: "80%",
    height: "auto",
    maxWidth: 180,
    [theme.breakpoints.down("sm")]: {
      width: "auto",
      height: "80%",
      maxWidth: 180,
    },
    logo: theme.logo
  },
  // Novo estilo para os botões de ícone
  iconButtonStyle: {
    color: "#fff !important", // Define a cor do ícone como branco
    backgroundColor: "#00000000", // Define a cor de fundo inicial como preto
    borderRadius: 8,
    padding: "6px",
    transition: "background-color 0.2s ease", // Transição suave para a mudança de cor
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.6)" // Cor de fundo preta com 60% de opacidade
    },
    "& .MuiSvgIcon-root": {
      color: "#fff !important", // Mantém a cor do ícone como branco no hover
      fontSize: "19px !important" // Tamanho definido do ícone
    }
  },
  tooltip: {
    backgroundColor: theme.palette.grey[700], // Cor de fundo do Tooltip
    color: "#fff", // Cor do texto
    fontSize: "0.8rem", // Aumenta o tamanho da fonte
    padding: theme.spacing(0.6, 0.6), // Aumenta o padding
    borderRadius: 6, // Bordas arredondadas
  },
  arrow: {
    color: theme.palette.grey[700], // Cor da seta
    fontSize: "0.6rem", // Aumenta o tamanho da seta
  },
}));

const LoggedInLayout = ({ children, themeToggle }) => {
  const classes = useStyles();
  const [userModalOpen, setUserModalOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [menuOpen, setMenuOpen] = useState(false);
  const { handleLogout, loading, user } = useContext(AuthContext);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [drawerVariant, setDrawerVariant] = useState("permanent");
  const { colorMode } = useContext(ColorModeContext);
  const { dateToClient } = useDate();
  const socketManager = useContext(SocketContext);
  const theme = useTheme();


  const location = useLocation();
  useEffect(() => {
    // Se estiver em mobile (ex: largura menor que 768px), feche o drawer quando a rota mudar
    if (window.innerWidth < 768) {
      setDrawerOpen(false);
    }
  }, [location]);
  
  const [volume, setVolume] = useState(localStorage.getItem("volume") || 1);

  const greaterThenSm = useMediaQuery(theme.breakpoints.up("sm"));

  useEffect(() => {
    if (document.body.offsetWidth > 1200) {
      setDrawerOpen(true);
    }
  }, []);

  useEffect(() => {
    if (document.body.offsetWidth < 600) {
      setDrawerVariant("permanent");
    } else {
      setDrawerVariant("permanent");
    }
  }, [drawerOpen]);

  useEffect(() => {
    const companyId = localStorage.getItem("companyId");
    const userId = localStorage.getItem("userId");
    const socket = socketManager.getSocket(companyId);

    socket.on(`company-${companyId}-auth`, (data) => {
      if (data.user.id === +userId) {
        toastError("Sua conta foi acessada em outro computador.");
        setTimeout(() => {
          localStorage.clear();
          window.location.reload();
        }, 1000);
      }
    });

    socket.emit("userStatus");
    const interval = setInterval(() => {
      socket.emit("userStatus");
    }, 1000 * 60 * 5);

    return () => {
      socket.disconnect();
      clearInterval(interval);
    };
  }, [socketManager]);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
    setMenuOpen(true);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
    setMenuOpen(false);
  };

  const handleOpenUserModal = () => {
    setUserModalOpen(true);
    handleCloseMenu();
  };

  const handleClickLogout = () => {
    setMenuOpen(false);
    handleLogout();
  };

  const handleRefreshPage = () => {
    window.location.reload(false);
  }

  if (loading) {
    return <BackdropLoading />;
  }

  const logo = `${process.env.REACT_APP_BACKEND_URL}/public/logotipos/interno.png`;
  const randomValue = Math.random(); // Gera um número aleatório

  const logoWithRandom = `${logo}?r=${randomValue}`;
  

  return (
    <div className={classes.root}>
      <Drawer
        variant={drawerVariant}
        className={drawerOpen ? classes.drawerPaper : classes.drawerPaperClose}
        classes={{
          paper: clsx(
            classes.drawerPaper,
            !drawerOpen && classes.drawerPaperClose
          ),
        }}
        open={drawerOpen}
      >
        <div className={classes.toolbarIcon}>
          <img src={logoWithRandom} style={{ margin: "0 auto", width: "50%" }} alt={`${process.env.REACT_APP_NAME_SYSTEM}`} />
          <IconButton
            onClick={() => setDrawerOpen(!drawerOpen)}
            className={classes.iconButtonStyle}
          >
            <ChevronLeftIcon />
          </IconButton>
        </div>
        <Divider />
        <List className={classes.containerWithScroll}>
          <MainListItems drawerClose={() => { }} collapsed={!drawerOpen} />
        </List>
        <Divider />
      </Drawer>
      <UserModal
        open={userModalOpen}
        onClose={() => setUserModalOpen(false)}
        userId={user?.id}
      />
      <AppBar
        position="absolute"
        className={clsx(classes.appBar, drawerOpen && classes.appBarShift)}
        color="primary"
      >
        <Toolbar variant="dense" className={classes.toolbar}>
          {/* Ícone Hamburguer */}
          <IconButton
            onClick={() => setDrawerOpen(!drawerOpen)}
            className={classes.iconButtonStyle}
          >
            {drawerOpen ? <MenuOpenIcon /> : <MenuIcon />}
          </IconButton>

          {/* Logo no Topo */}
          <img
            src={logoWithRandom}
            style={{ margin: "0 13px", width: "97px" }}
            alt={`${process.env.REACT_APP_NAME_SYSTEM}`}
          />

          {/* Título */}
          <Typography
            component="h2"
            variant="h6"
            color="black"
            noWrap
            className={classes.title}
          >
            {greaterThenSm && user?.profile === "admin" && user?.company?.dueDate ? (
              <>
                Olá, <b>{user.name}</b>. Bem-vindo ao WhatsNeed CRM!
              </>
            ) : (
              <>
                Olá, <b>{user.name}</b>. Bem-vindo ao WhatsNeed CRM!
              </>
            )}
          </Typography>

          {/* DATA ASSINATURA */}
          <Tooltip
            title={`Assinatura válida até ${dateToClient(user?.company?.dueDate)}`}
            arrow
            classes={{ tooltip: classes.tooltip, arrow: classes.arrow }}
          >
            <IconButton className={classes.iconButtonStyle}>
              <EventAvailableIcon />
            </IconButton>
          </Tooltip>

          {/* VOLUME */}
          <Tooltip
            title="Ajuste o volume"
            arrow
            classes={{ tooltip: classes.tooltip, arrow: classes.arrow }}
          >
            <span style={{ display: "inline-block" }}>
              <VolumePopover
                volume={volume}
                setVolume={setVolume}
                className={classes.iconButtonStyle}
              />
            </span>
          </Tooltip>

          {/* REFRESH */}
          <Tooltip
            title="Atualizar"
            arrow
            classes={{ tooltip: classes.tooltip, arrow: classes.arrow }}
          >
            <IconButton
              onClick={handleRefreshPage}
              className={classes.iconButtonStyle}
            >
              <CachedIcon />
            </IconButton>
          </Tooltip>

          {/* NOTIFICAÇÕES DAS MENSAGENS */}
          <Tooltip
            title="Tickets"
            arrow
            classes={{ tooltip: classes.tooltip, arrow: classes.arrow }}
          >
            <span style={{ display: "inline-block" }}>
              <NotificationsPopOver
                volume={volume}
                iconButtonClass={classes.iconButtonStyle}
              />
            </span>
          </Tooltip>

          {/* ANÚNCIOS */}
          <Tooltip
            title="Avisos"
            arrow
            classes={{ tooltip: classes.tooltip, arrow: classes.arrow }}
          >
            {/* Envolver em um elemento DOM */}
            <span style={{ display: "inline-block" }}>
              <AnnouncementsPopover iconButtonClass={classes.iconButtonStyle} />
            </span>
          </Tooltip>

          {/* CHAT INTERNO */}
          <Tooltip
            title="Chat Interno"
            arrow
            classes={{ tooltip: classes.tooltip, arrow: classes.arrow }}
          >
            <IconButton className={classes.iconButtonStyle}>
              <MailOutlineIcon />
            </IconButton>
          </Tooltip>

          {/* PERFIL (ÍCONE MAIS MODERNO) */}
          <Tooltip
            title="Perfil"
            arrow
            classes={{ tooltip: classes.tooltip, arrow: classes.arrow }}
          >
            <IconButton
              onClick={handleMenu}
              className={classes.iconButtonStyle}
            >
              <PersonOutlineIcon />
            </IconButton>
          </Tooltip>

          {/* MENU PERFIL */}
          <Menu
            id="menu-appbar"
            anchorEl={anchorEl}
            getContentAnchorEl={null}
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            transformOrigin={{ vertical: "top", horizontal: "right" }}
            open={menuOpen}
            onClose={handleCloseMenu}
          >
            <MenuItem onClick={handleOpenUserModal}>
              {i18n.t("mainDrawer.appBar.user.profile")}
            </MenuItem>
            <MenuItem onClick={handleClickLogout}>
              {i18n.t("mainDrawer.appBar.user.logout")}
            </MenuItem>
          </Menu>

          {/* LOGOUT */}
          <Tooltip
            title="Sair"
            arrow
            classes={{ tooltip: classes.tooltip, arrow: classes.arrow }}
          >
            <IconButton
              onClick={handleClickLogout}
              className={classes.iconButtonStyle}
            >
              <LogoutIcon />
            </IconButton>
          </Tooltip>
        </Toolbar>
      </AppBar>
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />

        {children ? children : null}
      </main>
    </div>
  );
};

export default LoggedInLayout;