import { toast } from "react-toastify"; // Importa o toast do react-toastify
import React from "react";
import "./toastStyles.css"; // Arquivo CSS para customização

const toastWarn = (message) => {
  const toastOptions = {
    autoClose: 5000, // Tempo de exibição
    hideProgressBar: true, // Esconde a barra de progresso
    closeOnClick: true,
    pauseOnHover: false,
    draggable: true,
    progress: undefined,
    className: "custom-warn-toast", // Classe CSS personalizada para o toast
    bodyClassName: "custom-warn-toast-body", // Classe para o conteúdo do corpo do toast
  };

  // Define o layout do toast
  const WarnToast = (
    <div className="custom-toast-content">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="custom-toast-icon"
        fill="none"
        viewBox="0 0 24 24"
      >
        <path
          stroke="currentColor"
          strokeWidth="2"
          d="M13 16h-1v-4h-1m1 4v-4m1 4v1h-2v-1h2zm0-4a4 4 0 11-8 0 4 4 0 018 0z"
        />
      </svg>
      <span>{message}</span>
    </div>
  );

  // Mostra o toast com a mensagem
  toast(WarnToast, toastOptions);
};

export default toastWarn; // Exporta para ser usado em outros arquivos