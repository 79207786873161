// src/components/QueueModal/index.js

import React, { useState, useEffect, useRef } from "react";
import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import toast from "../../errors/toast";
import { head } from "lodash";

import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import CircularProgress from "@material-ui/core/CircularProgress";

import {
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Tab,
  Tabs,
  Typography,
} from "@material-ui/core";
import { AttachFile, Colorize, DeleteOutline } from "@material-ui/icons";

import { i18n } from "../../translate/i18n";
import api from "../../services/api";
import toastError from "../../errors/toastError";
import ColorPicker from "../ColorPicker";
import { QueueOptions } from "../QueueOptions";
import SchedulesForm from "../SchedulesForm";
import ConfirmationModal from "../ConfirmationModal";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    fontFamily: "Montserrat, sans-serif",
  },
  textField: {
    "& .MuiOutlinedInput-root": {
      borderRadius: 6,
      padding: 0,
    },
    "& .MuiInputLabel-outlined": {
      fontSize: 16,
    },
    "& .MuiOutlinedInput-input": {
      padding: "12px 16px",
      fontSize: 16,
    },
    "& .MuiFormHelperText-root": {
      fontSize: 14,
    },
  },
  formControl: {
    minWidth: 120,
    width: "100%",
    "& .MuiInputLabel-outlined": {
      fontSize: 16,
    },
    "& .MuiOutlinedInput-input": {
      fontSize: 16,
    },
  },
  dialogPaper: {
    borderRadius: 13,
    padding: theme.spacing(3),
    backgroundColor: "#fff",
    maxWidth: 800,
    width: "100%",
    overflowX: "hidden",
  },
  attachButton: {
    borderRadius: 12,
    padding: "8px 16px",
    fontSize: 14,
    fontWeight: 500,
    textTransform: "none",
    backgroundColor: "#fff",
    color: "#000",
    border: "1px solid #ccc",
    "&:hover": {
      backgroundColor: "#f0f0f0",
    },
    "&:focus": {
      outline: "none",
      border: "1px solid #6F2CC0",
    },
  },
  submitButton: {
    borderRadius: 12,
    padding: "8px 16px",
    fontSize: 14,
    fontWeight: 500,
    textTransform: "none",
    backgroundColor: "#6F2CC0",
    color: "#fff",
    "&:hover": {
      backgroundColor: "#5a21a3",
    },
  },
  cancelButton: {
    borderRadius: 12,
    color: theme.palette.secondary.main,
    padding: "8px 16px",
    fontSize: 14,
    fontWeight: 500,
    textTransform: "none",
  },
  searchBarContainer: {
    display: "flex",
    justifyContent: "center",
    marginBottom: theme.spacing(5),
    width: "100%",
  },
  cardsContainer: {
    display: "grid",
    gridTemplateColumns: "repeat(auto-fit, minmax(280px, 1fr))",
    gap: theme.spacing(3),
    width: "100%",
  },
}));


// Schema de validação
const QueueSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, "Muito curto!")
    .max(50, "Muito longo!")
    .required("Obrigatório"),
  color: Yup.string()
    .min(3, "Muito curto!")
    .max(9, "Muito longo!")
    .required("Obrigatório"),
  greetingMessage: Yup.string(),
});

const QueueModal = ({ open, onClose, queueId }) => {
  const classes = useStyles();

  // Estado inicial
  const initialState = {
    name: "",
    color: "",
    greetingMessage: "",
    outOfHoursMessage: "",
    orderQueue: "",
    integrationId: "",
    promptId: "",
  };

  const [colorPickerModalOpen, setColorPickerModalOpen] = useState(false);
  const [queue, setQueue] = useState(initialState);
  const [tab, setTab] = useState(0);
  const [schedulesEnabled, setSchedulesEnabled] = useState(false);
  const [attachment, setAttachment] = useState(null);
  const attachmentFile = useRef(null);
  const greetingRef = useRef();
  const [integrations, setIntegrations] = useState([]);
  const [queueEditable, setQueueEditable] = useState(true);
  const [confirmationOpen, setConfirmationOpen] = useState(false);

  // Horários padrão
  const [schedules, setSchedules] = useState([
    {
      weekday: "Segunda-feira",
      weekdayEn: "monday",
      startTime: "08:00",
      endTime: "18:00",
    },
    {
      weekday: "Terça-feira",
      weekdayEn: "tuesday",
      startTime: "08:00",
      endTime: "18:00",
    },
    {
      weekday: "Quarta-feira",
      weekdayEn: "wednesday",
      startTime: "08:00",
      endTime: "18:00",
    },
    {
      weekday: "Quinta-feira",
      weekdayEn: "thursday",
      startTime: "08:00",
      endTime: "18:00",
    },
    {
      weekday: "Sexta-feira",
      weekdayEn: "friday",
      startTime: "08:00",
      endTime: "18:00",
    },
    {
      weekday: "Sábado",
      weekdayEn: "saturday",
      startTime: "08:00",
      endTime: "12:00",
    },
    {
      weekday: "Domingo",
      weekdayEn: "sunday",
      startTime: "00:00",
      endTime: "00:00",
    },
  ]);

  const [selectedPrompt, setSelectedPrompt] = useState(null);
  const [prompts, setPrompts] = useState([]);

  // Carrega a lista de prompts
  useEffect(() => {
    (async () => {
      try {
        const { data } = await api.get("/prompt");
        setPrompts(data.prompts);
      } catch (err) {
        toastError(err);
      }
    })();
  }, []);

  // Verifica se o schedule é "queue"
  useEffect(() => {
    api.get(`/settings`).then(({ data }) => {
      if (Array.isArray(data)) {
        const scheduleType = data.find((d) => d.key === "scheduleType");
        if (scheduleType) {
          setSchedulesEnabled(scheduleType.value === "queue");
        }
      }
    });
  }, []);

  // Carrega integrações
  useEffect(() => {
    (async () => {
      try {
        const { data } = await api.get("/queueIntegration");
        setIntegrations(data.queueIntegrations);
      } catch (err) {
        toastError(err);
      }
    })();
  }, []);

  // Se existir queueId, carrega a fila para edição
  useEffect(() => {
    (async () => {
      if (!queueId) return;
      try {
        const { data } = await api.get(`/queue/${queueId}`);
        setQueue((prevState) => ({ ...prevState, ...data }));
        // Se houver prompt
        if (data.promptId) setSelectedPrompt(data.promptId);
        // Se houver schedules
        setSchedules(data.schedules);
      } catch (err) {
        toastError(err);
      }
    })();

    return () => {
      setQueue(initialState);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queueId, open]);

  // Fecha o modal
  const handleClose = () => {
    onClose();
    setQueue(initialState);
  };

  // Manipula anexo
  const handleAttachmentFile = (e) => {
    const file = head(e.target.files);
    if (file) {
      setAttachment(file);
    }
  };

  // Deleta anexo
  const deleteMedia = async () => {
    if (attachment) {
      setAttachment(null);
      attachmentFile.current.value = null;
    }
    if (queue.mediaPath) {
      await api.delete(`/queue/${queue.id}/media-upload`);
      setQueue((prev) => ({ ...prev, mediaPath: null, mediaName: null }));
      toast.success(i18n.t("queueModal.toasts.deleted"));
    }
  };

  // Salva a fila (Edição ou Criação)
  const handleSaveQueue = async (values) => {
    try {
      if (queueId) {
        // Edição
        await api.put(`/queue/${queueId}`, {
          ...values,
          schedules,
          promptId: selectedPrompt || null,
        });
        if (attachment) {
          const formData = new FormData();
          formData.append("file", attachment);
          await api.post(`/queue/${queueId}/media-upload`, formData);
        }
      } else {
        // Criação
        const response = await api.post("/queue", {
          ...values,
          schedules,
          promptId: selectedPrompt || null,
        });
        if (attachment) {
          const formData = new FormData();
          formData.append("file", attachment);
          await api.post(`/queue/${response.data.id}/media-upload`, formData);
        }
      }
      // Mensagem antiga "Queue saved successfully"
      toast.success("Departamento salvo com sucesso");
      handleClose();
    } catch (err) {
      toastError(err);
    }
  };

  // Salva horários
  const handleSaveSchedules = async (values) => {
    // Mensagem antiga: "Clique em salvar para registrar as alterações"
    toast.success("Clique em salvar para registrar as alterações");
    setSchedules(values);
    setTab(0);
  };

  // Muda Prompt
  const handleChangePrompt = (e) => {
    const value = e.target.value;
    if (value === "none" || value === "") {
      setSelectedPrompt(null);
    } else {
      setSelectedPrompt(value);
    }
  };

  return (
    <div className={classes.root}>
      <ConfirmationModal
        title={i18n.t("queueModal.confirmationModal.deleteTitle")}
        open={confirmationOpen}
        onClose={() => setConfirmationOpen(false)}
        onConfirm={deleteMedia}
      />

      <Dialog
        maxWidth="md"
        fullWidth
        open={open}
        onClose={handleClose}
        scroll="paper"
        PaperProps={{
          className: classes.dialogPaper,
        }}
      >
        <DialogTitle>
          <Typography variant="h6">
            {/* Traduções antigas para o título */}
            {queueId ? "Editar departamento" : "Criar departamento"}
          </Typography>
          {/* Input de arquivo escondido */}
          <div style={{ display: "none" }}>
            <input
              type="file"
              ref={attachmentFile}
              onChange={handleAttachmentFile}
            />
          </div>
        </DialogTitle>

        <Tabs
          value={tab}
          indicatorColor="primary"
          textColor="primary"
          onChange={(_, val) => setTab(val)}
          aria-label="tabs example"
        >
          {/* Tabs antigas: "Dados da Fila", "Horários de Atendimento" */}
          <Tab label="Dados do Departamento" />
          {schedulesEnabled && <Tab label="Horários de Atendimento" />}
        </Tabs>

        {tab === 0 && (
          <Paper elevation={0}>
            <Formik
              initialValues={queue}
              enableReinitialize
              validationSchema={QueueSchema}
              onSubmit={(values, actions) => {
                setTimeout(() => {
                  handleSaveQueue(values);
                  actions.setSubmitting(false);
                }, 400);
              }}
            >
              {({ touched, errors, isSubmitting, values }) => (
                <Form>
                  <DialogContent dividers>
                    {/* Linha 1: Nome, Cor, Ordem */}
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={4}>
                        <Field
                          as={TextField}
                          label="Nome"
                          autoFocus
                          name="name"
                          error={touched.name && Boolean(errors.name)}
                          helperText={touched.name && errors.name}
                          variant="outlined"
                          margin="dense"
                          className={classes.textField}
                        />
                      </Grid>

                      <Grid item xs={12} sm={4}>
                        <Field
                          as={TextField}
                          label="Cor"
                          name="color"
                          id="color"
                          onFocus={() => {
                            setColorPickerModalOpen(true);
                            greetingRef.current.focus();
                          }}
                          error={touched.color && Boolean(errors.color)}
                          helperText={touched.color && errors.color}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <div
                                  className={classes.colorAdornment}
                                  style={{ backgroundColor: values.color }}
                                />
                              </InputAdornment>
                            ),
                            endAdornment: (
                              <IconButton
                                size="small"
                                color="default"
                                onClick={() => setColorPickerModalOpen(true)}
                              >
                                <Colorize />
                              </IconButton>
                            ),
                          }}
                          variant="outlined"
                          margin="dense"
                          className={classes.textField}
                        />
                        <ColorPicker
                          open={colorPickerModalOpen}
                          handleClose={() => setColorPickerModalOpen(false)}
                          onChange={(color) => {
                            values.color = color;
                            setQueue((prev) => ({ ...prev, color }));
                          }}
                        />
                      </Grid>

                      <Grid item xs={12} sm={4}>
                        <Field
                          as={TextField}
                          label="Ordem da Fila"
                          name="orderQueue"
                          type="number"
                          error={touched.orderQueue && Boolean(errors.orderQueue)}
                          helperText={touched.orderQueue && errors.orderQueue}
                          variant="outlined"
                          margin="dense"
                          className={classes.textField}
                        />
                      </Grid>
                    </Grid>

                    {/* Linha 2: Integração, Prompt */}
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6}>
                        <FormControl
                          variant="outlined"
                          margin="dense"
                          className={classes.formControl}
                        >
                          <InputLabel id="integrationId-selection-label">
                            Integração
                          </InputLabel>
                          <Field
                            as={Select}
                            labelId="integrationId-selection-label"
                            id="integrationId"
                            name="integrationId"
                            label="Integração"
                          >
                            <MenuItem value="">Nenhum</MenuItem>
                            {integrations.map((integration) => (
                              <MenuItem
                                key={integration.id}
                                value={integration.id}
                              >
                                {integration.name}
                              </MenuItem>
                            ))}
                          </Field>
                        </FormControl>
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        <FormControl
                          variant="outlined"
                          margin="dense"
                          className={classes.formControl}
                        >
                          <InputLabel id="dialog-select-prompt-label">
                            Prompt
                          </InputLabel>
                          <Field
                            as={Select}
                            labelId="dialog-select-prompt-label"
                            id="dialog-select-prompt"
                            name="promptId"
                            value={selectedPrompt || ""}
                            onChange={handleChangePrompt}
                            label="Prompt"
                            MenuProps={{
                              anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "left",
                              },
                              transformOrigin: {
                                vertical: "top",
                                horizontal: "left",
                              },
                              getContentAnchorEl: null,
                            }}
                          >
                            <MenuItem value="none">
                              <em>{i18n.t("queueModal.noPrompt")}</em>
                            </MenuItem>
                            {prompts.map((prompt) => (
                              <MenuItem key={prompt.id} value={prompt.id}>
                                {prompt.name}
                              </MenuItem>
                            ))}
                          </Field>
                        </FormControl>
                      </Grid>
                    </Grid>

                    {/* Linha 3: Mensagens (Saudação e Fora de Expediente) */}
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Field
                          as={TextField}
                          label="Mensagem de Saudação"
                          name="greetingMessage"
                          multiline
                          rows={3}
                          fullWidth
                          inputRef={greetingRef}
                          error={
                            touched.greetingMessage &&
                            Boolean(errors.greetingMessage)
                          }
                          helperText={
                            touched.greetingMessage && errors.greetingMessage
                          }
                          variant="outlined"
                          margin="dense"
                          className={classes.textField}
                          InputLabelProps={{
                            shrink: true,
                            style: { fontSize: 16 },
                          }}
                          InputProps={{
                            style: { fontSize: 16 },
                          }}
                        />
                      </Grid>

                      {schedulesEnabled && (
                        <Grid item xs={12}>
                          <Field
                            as={TextField}
                            label="Mensagem Fora do Expediente"
                            name="outOfHoursMessage"
                            multiline
                            rows={3}
                            fullWidth
                            error={
                              touched.outOfHoursMessage &&
                              Boolean(errors.outOfHoursMessage)
                            }
                            helperText={
                              touched.outOfHoursMessage &&
                              errors.outOfHoursMessage
                            }
                            variant="outlined"
                            margin="dense"
                            className={classes.textField}
                            InputLabelProps={{
                              shrink: true,
                              style: { fontSize: 16 },
                            }}
                            InputProps={{
                              style: { fontSize: 16 },
                            }}
                          />
                        </Grid>
                      )}
                    </Grid>

                    {/* Opções adicionais de fila */}
                    <QueueOptions queueId={queueId} />

                    {/* Anexo (se existir) */}
                    {(queue.mediaPath || attachment) && (
                      <Grid
                        container
                        spacing={1}
                        className={classes.attachmentContainer}
                      >
                        <Button
                          startIcon={<AttachFile />}
                          variant="contained"
                          className={classes.attachButton}
                          disabled={!queueEditable}
                        >
                          {attachment ? attachment.name : queue.mediaName}
                        </Button>
                        {queueEditable && (
                          <IconButton
                            onClick={() => setConfirmationOpen(true)}
                            className={classes.deleteButton}
                          >
                            <DeleteOutline />
                          </IconButton>
                        )}
                      </Grid>
                    )}
                  </DialogContent>

                  <DialogActions>
                    {/* Botão de anexar arquivo (só aparece se não existir anexo) */}
                    {!attachment && !queue.mediaPath && queueEditable && (
                      <Button
                        color="default"
                        onClick={() => attachmentFile.current.click()}
                        disabled={isSubmitting}
                        variant="outlined"
                        className={classes.attachButton}
                        size="medium"
                      >
                        Anexar
                      </Button>
                    )}
                    {/* Botão Cancelar */}
                    <Button
                      onClick={handleClose}
                      color="secondary"
                      disabled={isSubmitting}
                      variant="outlined"
                      className={classes.cancelButton}
                      size="medium"
                    >
                      Cancelar
                    </Button>
                    {/* Botão Salvar */}
                    <Button
                      type="submit"
                      color="primary"
                      disabled={isSubmitting}
                      variant="contained"
                      className={`${classes.submitButton} ${classes.btnWrapper}`}
                      size="medium"
                    >
                      {queueId ? "Salvar Edição" : "Salvar"}
                      {isSubmitting && (
                        <CircularProgress
                          size={20}
                          className={classes.buttonProgress}
                        />
                      )}
                    </Button>
                  </DialogActions>
                </Form>
              )}
            </Formik>
          </Paper>
        )}

        {tab === 1 && (
          <Paper elevation={0} style={{ padding: "3rem" }}>
            <SchedulesForm
              loading={false}
              onSubmit={handleSaveSchedules}
              initialValues={schedules}
              labelSaveButton="Adicionar"
            />
          </Paper>
        )}
      </Dialog>
    </div>
  );
};

export default QueueModal;