import { toast } from "react-toastify";
import React from "react";
import { i18n } from "../translate/i18n"; // Importa o módulo de tradução
import "./toastStyles.css"; // Arquivo CSS para customização

const toastError = (err) => {
  // Obtém a mensagem de erro retornada pelo back-end
  const errorMsg = err.response?.data?.error || "An error occurred!";
  
  // Verifica se a chave de erro existe no arquivo de tradução
  const translatedError = i18n.exists(`backendErrors.${errorMsg}`)
    ? i18n.t(`backendErrors.${errorMsg}`) // Usa a tradução
    : errorMsg; // Caso não exista, mantém a mensagem original

  const toastOptions = {
    autoClose: 10000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: false,
    draggable: true,
    progress: undefined,
    className: "custom-error-toast", // Classe CSS personalizada
    bodyClassName: "custom-error-toast-body", // Classe para o conteúdo do corpo do toast
  };

  const ErrorToast = (
    <div className="custom-toast-content">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="custom-toast-icon"
        fill="none"
        viewBox="0 0 24 24"
      >
        <path
          stroke="currentColor"
          strokeWidth="2"
          d="M6 18L18 6M6 6l12 12"
        />
      </svg>
      <span>{translatedError}</span>
    </div>
  );

  // Exibe o toast com a mensagem traduzida (ou original, caso não haja tradução)
  toast(ErrorToast, toastOptions);
};

export default toastError;