import React, { useState } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { Link as RouterLink, useHistory } from "react-router-dom";
import {
  createTheme,
  ThemeProvider,
  Container,
  CssBaseline,
  TextField,
  Button,
  Grid,
  Link,
  Typography,
  Box,
  IconButton,
  InputAdornment
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";

/** Ajuste estes imports conforme seu projeto. */
import api from "../../services/api";
import toastError from "../../errors/toastError";
import { toast } from "react-toastify";

/** Ajuste o caminho do seu logo aqui */
import logo from "../../assets/logo3.png";

/* ------------------------------------------------------------------
   1. Tema customizado do Material UI, para forçar estilos exatos
   ------------------------------------------------------------------ */
const customTheme = createTheme({
  typography: {
    fontFamily: [
      "Poppins",
      "Roboto",
      "Helvetica Neue",
      "Arial",
      "sans-serif"
    ].join(","),
  },
  palette: {
    primary: {
      main: "#9C4DE3" // Roxo padrão; ajuste se quiser outra cor
    }
  },
  overrides: {
    // Forçar estilização nos TextFields
    MuiOutlinedInput: {
      root: {
        borderRadius: 12,
        backgroundColor: "#FFFFFF" /* Tom quase branco */,
        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
          borderColor: "#D8D8D8",
        },
        "&:hover .MuiOutlinedInput-notchedOutline": {
          borderColor: "#E0E0E0",
        },
      },
      notchedOutline: {
        borderColor: "#E6E6E6", // Cor da borda
      },
      input: {
        // Placeholder
        "&::placeholder": {
          color: "#9B9B9B",
          opacity: 1,
        },
      },
    },
    MuiInputLabel: {
      // Cor do label "Email"
      root: {
        color: "#4A4A4A",
        fontSize: "0.95rem",
      },
    },
  },
});

/* ------------------------------------------------------------------
   2. Estilos (makeStyles) para layout do container, card, etc.
   ------------------------------------------------------------------ */
const useStyles = makeStyles((theme) => ({
  "@keyframes gradient": {
    "0%": {
      backgroundPosition: "0% 50%"
    },
    "50%": {
      backgroundPosition: "100% 50%"
    },
    "100%": {
      backgroundPosition: "0% 50%"
    }
  },
  /** Fundo em gradiente lilás claro → branco, centraliza tudo */
  root: {
    width: "100vw",
    height: "100vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    background: "linear-gradient(270deg,#000000, #4B00A2, #000000)",
    backgroundSize: "800% 800%",
    animation: "$gradient 12s ease infinite",
  },

  /** Card branco arredondado, sombra sutil, ~400px de largura */
  card: {
    backgroundColor: "#FFF",
    borderRadius: 20,
    boxShadow: "0 4px 20px rgba(0, 0, 0, 0.05)",
    maxWidth: 400,
    width: "100%",
    padding: theme.spacing(4),
    textAlign: "center",
  
    [theme.breakpoints.down("xs")]: {
      maxWidth: "90%",       // Ou 90% se preferir
      padding: theme.spacing(2),
      margin: "0 auto",      // Centraliza horizontalmente
      borderRadius: 16       // Menos arredondado, se quiser
    },
  },

  /** Logo no topo, centralizada */
  logo: {
    display: "block",
    margin: "0 auto",
    marginBottom: theme.spacing(2),
    width: "100%",
    maxWidth: 200,
  },

  /** Título "Redefinir Senha" */
  title: {
    fontWeight: 600,
    fontSize: "1.3rem",
    color: "#2E2E2E",
    marginBottom: theme.spacing(2),
  },

  /** Formulário */
  form: {
    width: "100%",
    marginTop: theme.spacing(1),
  },

  /** Cada campo */
  inputField: {
    marginBottom: theme.spacing(2),
    "& .MuiOutlinedInput-root": {
      borderRadius: 12, // Arredonda a borda do input
      fontSize: "0.95rem",
    },
  },

  /** Botão roxo grande, bordas arredondadas */
  submitButton: {
    marginTop: theme.spacing(3),
    borderRadius: 12,
    textTransform: "uppercase",
    fontWeight: 600,
    height: 42,
    backgroundColor: "#a201ff", // Ajuste se quiser outro roxo exato
    color: "#FFF",
    "&:hover": {
      backgroundColor: "#862CC7",
    },
  },

  /** Espaço extra antes do link de rodapé */
  footerContainer: {
    marginTop: theme.spacing(3), // Espaçamento extra para afastar a frase do botão
  },

  /** Link no rodapé (Cadastre-se!) */
  footerLink: {
    color: "#9C4DE3",
    fontWeight: 400,
    fontSize: "0.9rem",
    textDecoration: "none",
    "&:hover": {
      textDecoration: "underline",
    },
  },
}));

/* ------------------------------------------------------------------
   3. Validação do formulário via Yup
   ------------------------------------------------------------------ */
const UserSchema = Yup.object().shape({
  email: Yup.string().email("E-mail inválido").required("Campo obrigatório"),
});

/* ------------------------------------------------------------------
   4. Componente principal: ForgetPassword
   ------------------------------------------------------------------ */
const ForgetPassword = () => {
  const classes = useStyles();
  const history = useHistory();

  const [showPasswordFields, setShowPasswordFields] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  /** Envia e-mail para redefinir senha (1ª etapa) */
  const handleSendEmail = async (values) => {
    try {
      const resp = await api.post(`/forgetpassword/${values.email}`);
      if (resp.data.status === 404) {
        toast.error("E-mail não encontrado!");
      } else {
        toast.success("Enviamos um código de verificação ao seu e-mail!");
        setShowPasswordFields(true);
      }
    } catch (err) {
      toastError(err);
    }
  };

  /** Redefine a senha (2ª etapa) */
  const handleResetPassword = async (values) => {
    try {
      await api.post(
        `/resetpasswords/${values.email}/${values.token}/${values.newPassword}`
      );
      toast.success("Senha redefinida com sucesso!");
      history.push("/login");
    } catch (err) {
      toastError(err);
    }
  };

  return (
    <ThemeProvider theme={customTheme}>
      <div className={classes.root}>
        <Container maxWidth="xs" className={classes.card}>
          <CssBaseline />

          {/* Logo */}
          <img src={logo} alt="Logo" className={classes.logo} />

          {/* Título */}
          <Typography className={classes.title}>Redefinir senha</Typography>

          {/* Formulário com Formik */}
          <Formik
            initialValues={{
              email: "",
              token: "",
              newPassword: "",
              confirmPassword: "",
            }}
            validationSchema={UserSchema}
            onSubmit={(values, actions) => {
              if (!showPasswordFields) {
                // 1ª etapa
                handleSendEmail(values);
              } else {
                // 2ª etapa
                handleResetPassword(values);
              }
              actions.setSubmitting(false);
            }}
          >
            {({ touched, errors }) => (
              <Form className={classes.form}>
                {/* Campo email */}
                <Field
                  as={TextField}
                  name="email"
                  label="Email"
                  variant="outlined"
                  fullWidth
                  className={classes.inputField}
                  error={touched.email && Boolean(errors.email)}
                  helperText={touched.email && errors.email}
                />

                {/* Se já tiver enviado email, mostra token e senha */}
                {showPasswordFields && (
                  <>
                    {/* Código de verificação */}
                    <Field
                      as={TextField}
                      name="token"
                      label="Código de Verificação"
                      variant="outlined"
                      fullWidth
                      className={classes.inputField}
                    />

                    {/* Nova senha */}
                    <Field
                      as={TextField}
                      name="newPassword"
                      label="Nova senha"
                      variant="outlined"
                      fullWidth
                      type={showPassword ? "text" : "password"}
                      className={classes.inputField}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={() => setShowPassword(!showPassword)}
                            >
                              {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />

                    {/* Confirmação de senha */}
                    <Field
                      as={TextField}
                      name="confirmPassword"
                      label="Confirme a senha"
                      variant="outlined"
                      fullWidth
                      type={showConfirmPassword ? "text" : "password"}
                      className={classes.inputField}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                            >
                              {showConfirmPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </>
                )}

                {/* Botão principal */}
                <Button
                  type="submit"
                  variant="contained"
                  fullWidth
                  className={classes.submitButton}
                >
                  {showPasswordFields ? "Redefinir Senha" : "Enviar Email"}
                </Button>
              </Form>
            )}
          </Formik>

          {/* Rodapé: link para signup
              Adicionamos marginTop extra para afastar do botão */}
          <Grid
            container
            justifyContent="center"
            className={classes.footerContainer}
          >
            <Grid item>
              <Link
                component={RouterLink}
                to="/signup"
                className={classes.footerLink}
              >
                Não tem uma conta? Cadastre-se!
              </Link>
            </Grid>
          </Grid>

          <Box mt={2} />
        </Container>
      </div>
    </ThemeProvider>
  );
};

export default ForgetPassword;