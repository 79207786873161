import React, { useContext, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

// Ícones
import DoneAllIcon from "@material-ui/icons/DoneAll";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import CallIcon from "@material-ui/icons/Call";
import MobileFriendlyIcon from "@material-ui/icons/MobileFriendly";
import StoreIcon from "@material-ui/icons/Store";
import HourglassEmptyIcon from "@material-ui/icons/HourglassEmpty";
import AccessAlarmIcon from "@material-ui/icons/AccessAlarm";
import TimerIcon from "@material-ui/icons/Timer";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import FormHelperText from "@material-ui/core/FormHelperText";
import { Button } from "@material-ui/core";
import SpeedIcon from "@material-ui/icons/Speed";
import GroupIcon from "@material-ui/icons/Group";
import AssignmentIcon from "@material-ui/icons/Assignment";
import PersonIcon from "@material-ui/icons/Person";
import RecordVoiceOverIcon from "@material-ui/icons/RecordVoiceOver";
import GroupAddIcon from "@material-ui/icons/GroupAdd";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ForumIcon from "@material-ui/icons/Forum";
import FilterListIcon from "@material-ui/icons/FilterList";
import ClearIcon from "@material-ui/icons/Clear";
import SendIcon from "@material-ui/icons/Send";
import MessageIcon from "@material-ui/icons/Message";
import PhoneInTalkIcon from "@material-ui/icons/PhoneInTalk";

import {
  Paper,
  Container,
  Grid,
  Typography,
  Box
} from "@material-ui/core";

import { makeStyles } from "@material-ui/core/styles";
import { grey, blue } from "@material-ui/core/colors";
import toast from "../../errors/toast"; // Importando o toast que agora usa o toastSuccess personalizado

import Chart from "./Chart";
import ButtonWithSpinner from "../../components/ButtonWithSpinner";

import CardCounter from "../../components/Dashboard/CardCounter";
import TableAttendantsStatus from "../../components/Dashboard/TableAttendantsStatus";
import { isArray, isEmpty } from "lodash";

import { AuthContext } from "../../context/Auth/AuthContext";

import useDashboard from "../../hooks/useDashboard";
import useTickets from "../../hooks/useTickets";
import useUsers from "../../hooks/useUsers";
import useContacts from "../../hooks/useContacts";
import useMessages from "../../hooks/useMessages";
import { ChatsUser } from "./ChartsUser";
import Filters from "./Filters";
import moment from "moment";
import { ChartsDate } from "./ChartsDate";

const useStyles = makeStyles((theme) => ({
  headerTitle: {
    color: "#000000",
    fontWeight: "bold",
    margin: 0,
    fontSize: "1.2rem"
  },
  // -------------------- FILTROS --------------------
  alignRight: {
    textAlign: "right",
  },
  selectContainer: {
    width: "100%",
    textAlign: "left",
  },
  fullWidth: {
    width: "100%",
  },
  fixedHeightPaper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
    height: 240,
  },
  customFixedHeightPaper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
    height: 120,
  },
  customFixedHeightPaperLg: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
    height: "100%",
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  container2: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    display: "flex",
  },
  // -------------------- CARDS --------------------
  card: {
    background: "white",
    padding: "20px",
    borderRadius: "12px",
    boxShadow: "0 2px 8px rgba(0,0,0,0.08)",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-start",
    minHeight: "120px",
    transition: "transform 0.2s ease, box-shadow 0.2s ease",
    border: "0px solid #f0f0f0",
    "&:hover": {
      transform: "translateY(-4px)",
      boxShadow: "0 4px 12px rgba(0,0,0,0.12)",
    }
  },
  LPaper: {
    boxShadow: "0 4px 12px rgba(0,0,0,0.15)",
    background: "white",
    padding: "20px",
    width: "100%",
    borderRadius: "13px",
    display: "flex",
    flexDirection: "column",
    height: "100%",
    minHeight: "400px",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column"
    },
  },
  cardIconContainer: {
    width: "36px",
    height: "36px",
    borderRadius: "8px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "rgba(111, 44, 192, 0.1)",
    "& svg": {
      fontSize: "20px",
      color: "#6F2CC0",
    }
  },
  cardTitle: {
    fontSize: "14px",
    fontWeight: 500,
    color: "#666",
    textTransform: "uppercase",
    letterSpacing: "0.5px",
    marginBottom: "8px",
  },
  cardParagrafo: {
    fontSize: "13px",
    color: "#22C55E",
    fontWeight: 500,
  },
  cardValue: {
    fontSize: "28px",
    fontWeight: 600,
    color: "#333",
    marginBottom: "4px",
  },
  cardAcontecendo: {
    "& $cardIconContainer": {
      backgroundColor: "rgba(59, 130, 246, 0.1)",
      "& svg": { color: "#3B82F6" }
    },
    borderLeft: "4px solid #3B82F6",
  },
  cardPendentes: {
    "& $cardIconContainer": {
      backgroundColor: "rgba(21, 184, 166, 0.1)",
      "& svg": { color: "#15B8A6" }
    },
    borderLeft: "4px solid #15B8A6",
  },
  cardNovosContatos: {
    "& $cardIconContainer": {
      backgroundColor: "rgba(34, 197, 94, 0.1)",
      "& svg": { color: "#22C55E" }
    },
    borderLeft: "4px solid #22C55E",
  },
  cardAtendimento: {
    "& $cardIconContainer": {
      backgroundColor: "rgba(168, 85, 247, 0.1)",
      "& svg": { color: "#A855F7" }
    },
    borderLeft: "4px solid #A855F7",
  },
  cardEspera: {
    "& $cardIconContainer": {
      backgroundColor: "rgba(249, 115, 22, 0.1)",
      "& svg": { color: "#F97316" }
    },
    borderLeft: "4px solid #F97316",
  },
  cardFinalizados: {
    "& $cardIconContainer": {
      backgroundColor: "rgba(111, 44, 192, 0.1)",
      "& svg": { color: "#6F2CC0" }
    },
    borderLeft: "4px solid #6F2CC0",
  },
  ContainerChart: {
    padding: "10px",
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column"
    },
  },
  cardTL: {
    color: "#6F2CC0",
    fontSize: 21,
    fontWeight: 500,
    marginBottom: 4,
  },
  espace: {
    paddingRight: "20px",
    [theme.breakpoints.down("sm")]: {
      paddingBottom: "20px"
    },
  },
  filterPaper: {
    background: "#FFFFFF",
    padding: "16px",
    borderRadius: "12px",
    boxShadow: "0 2px 8px rgba(0,0,0,0.08)",
    marginBottom: theme.spacing(3),
  },
  filterContainer: {
    display: "flex",
    alignItems: "center",
    gap: "12px",
    flexWrap: "nowrap",
    "& > *:not(:last-child)": {
      flex: 1,
    },
    "& > :last-child": {
      marginLeft: "auto",
    },
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      alignItems: "stretch",
      "& > :last-child": {
        marginLeft: 0,
      },
    }
  },
  filterField: {
    "& .MuiOutlinedInput-root": {
      borderRadius: "8px",
      backgroundColor: "#FFFFFF",
      "& fieldset": {
        borderColor: "#E5E7EB",
      },
      "&:hover fieldset": {
        borderColor: "#6F2CC0",
      },
    },
    "& .MuiInputLabel-root": {
      color: "#6B7280",
      fontSize: "14px",
    },
    "& .MuiInputBase-input": {
      padding: "10px 14px",
      height: "20px",
      fontSize: "14px",
    },
  },
  filterButton: {
    backgroundColor: "#6F2CC0",
    color: "#FFFFFF",
    padding: "10px 24px",
    borderRadius: "8px",
    textTransform: "none",
    fontWeight: 600,
    fontSize: "14px",
    minWidth: "100px",
    height: "40px",
    marginLeft: "auto",
    "&:hover": {
      backgroundColor: "#5a23a0",
    },
  },
  dateField: {
    "& .MuiOutlinedInput-root": {
      borderRadius: "8px",
      backgroundColor: "#FFFFFF",
      "& fieldset": {
        borderColor: "#E5E7EB",
      },
    },
    "& .MuiInputLabel-root": {
      color: "#6B7280",
      fontSize: "14px",
    },
    "& .MuiInputBase-input": {
      padding: "10px 14px",
      height: "20px",
      fontSize: "14px",
    },
  },
}));

const Dashboard = () => {
  const history = useHistory();
  const classes = useStyles();
  const [counters, setCounters] = useState({});
  const [attendants, setAttendants] = useState([]);
  const [period, setPeriod] = useState(0);
  const [filterType, setFilterType] = useState(1);
  const [dateFrom, setDateFrom] = useState(
    moment("1", "D").format("YYYY-MM-DD")
  );
  const [dateTo, setDateTo] = useState(moment().format("YYYY-MM-DD"));
  const [loading, setLoading] = useState(false);
  const { find } = useDashboard();

  let newDate = new Date();
  let date = newDate.getDate();
  let month = newDate.getMonth() + 1;
  let year = newDate.getFullYear();
  let now = `${year}-${month < 10 ? `0${month}` : `${month}`}-${date < 10 ? `0${date}` : `${date}`
    }`;

  const [showFilter, setShowFilter] = useState(false);
  const [queueTicket, setQueueTicket] = useState(false);

  const { user } = useContext(AuthContext);
  var userQueueIds = [];

  if (user.queues && user.queues.length > 0) {
    userQueueIds = user.queues.map((q) => q.id);
  }

  useEffect(() => {
    if (user.confirmedphone !== "true") {
      history.push("/phone");
    }

    async function firstLoad() {
      await fetchData();
    }
    setTimeout(() => {
      firstLoad();
    }, 1000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function handleChangePeriod(value) {
    setPeriod(value);
  }

  async function handleChangeFilterType(value) {
    setFilterType(value);
    if (value === 1) {
      setPeriod(0);
    } else {
      setDateFrom("");
      setDateTo("");
    }
  }

  async function fetchData() {
    setLoading(true);

    let params = {};

    if (period > 0) {
      params = {
        days: period,
      };
    }

    if (!isEmpty(dateFrom) && moment(dateFrom).isValid()) {
      params = {
        ...params,
        date_from: moment(dateFrom).format("YYYY-MM-DD"),
      };
    }

    if (!isEmpty(dateTo) && moment(dateTo).isValid()) {
      params = {
        ...params,
        date_to: moment(dateTo).format("YYYY-MM-DD"),
      };
    }

    if (Object.keys(params).length === 0) {
      toast.error("Parametrize o filtro");
      setLoading(false);
      return;
    }

    const data = await find(params);

    setCounters(data.counters);
    if (isArray(data.attendants)) {
      setAttendants(data.attendants);
    } else {
      setAttendants([]);
    }

    setLoading(false);
  }

  function formatTime(minutes) {
    return moment().startOf("day").add(minutes, "minutes").format("HH[h] mm[m]");
  }

  const GetUsers = () => {
    let userOnline = 0;
    attendants.forEach((u) => {
      if (u.online === true) {
        userOnline++;
      }
    });
    return userOnline === 0 ? 0 : userOnline;
  };

  const GetContacts = (all) => {
    let props = {};
    if (all) {
      props = {};
    }
    const { count } = useContacts(props);
    return count;
  };

  // Adicionar um useEffect para debug
  useEffect(() => {
    console.log("Counters atualizados:", counters);
  }, [counters]);

  return (
    <div>
      <Container maxWidth="lg" className={classes.container}>
        {/* Filtros */}
        <Paper className={classes.filterPaper} elevation={0}>
          <div className={classes.filterContainer}>
            <FormControl variant="outlined" className={classes.filterField} style={{ minWidth: 200 }}>
              <InputLabel>Tipo de Filtro</InputLabel>
              <Select
                value={filterType}
                onChange={(e) => handleChangeFilterType(e.target.value)}
                label="Tipo de Filtro"
              >
                <MenuItem value={1}>Filtro por Data</MenuItem>
                <MenuItem value={2}>Filtro por Período</MenuItem>
              </Select>
            </FormControl>

            {filterType === 1 ? (
              <>
                <TextField
                  variant="outlined"
                  label="Data Inicial"
                  type="date"
                  value={dateFrom}
                  onChange={(e) => setDateFrom(e.target.value)}
                  className={classes.dateField}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                <TextField
                  variant="outlined"
                  label="Data Final"
                  type="date"
                  value={dateTo}
                  onChange={(e) => setDateTo(e.target.value)}
                  className={classes.dateField}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </>
            ) : (
              <FormControl variant="outlined" className={classes.filterField} style={{ minWidth: 200 }}>
                <InputLabel>Período</InputLabel>
                <Select
                  value={period}
                  onChange={(e) => handleChangePeriod(e.target.value)}
                  label="Período"
                >
                  <MenuItem value={0}>Nenhum selecionado</MenuItem>
                  <MenuItem value={3}>Últimos 3 dias</MenuItem>
                  <MenuItem value={7}>Últimos 7 dias</MenuItem>
                  <MenuItem value={15}>Últimos 15 dias</MenuItem>
                  <MenuItem value={30}>Últimos 30 dias</MenuItem>
                  <MenuItem value={60}>Últimos 60 dias</MenuItem>
                  <MenuItem value={90}>Últimos 90 dias</MenuItem>
                </Select>
              </FormControl>
            )}

            <Button
              variant="contained"
              className={classes.filterButton}
              onClick={() => fetchData()}
              disabled={loading}
            >
              {loading ? "Filtrando..." : "Filtrar"}
            </Button>
          </div>
        </Paper>

        {/* Cards em Grid */}
        <Grid container spacing={3} justifyContent="flex-end">
          {/* Atd. Acontecendo */}
          <Grid item xs={12} sm={6} md={4}>
            <Paper className={`${classes.card} ${classes.cardAcontecendo}`} elevation={0}>
              <Box>
                <Typography className={classes.cardTitle}>
                  ACONTECENDO
                </Typography>
                <Typography className={classes.cardValue}>
                  {counters.supportHappening}
                </Typography>
                <Typography className={classes.cardParagrafo}>
                                   </Typography>
              </Box>
              <Box className={classes.cardIconContainer}>
                <HourglassEmptyIcon />
              </Box>
            </Paper>
          </Grid>

          {/* Atd. Pendentes */}
          <Grid item xs={12} sm={6} md={4}>
            <Paper className={`${classes.card} ${classes.cardPendentes}`} elevation={0}>
              <Box>
                <Typography className={classes.cardTitle}>
                  PENDENTES
                </Typography>
                <Typography className={classes.cardValue}>
                  {counters.supportPending}
                </Typography>
                <Typography className={classes.cardParagrafo}>
                                   </Typography>
              </Box>
              <Box className={classes.cardIconContainer}>
                <PhoneInTalkIcon />
              </Box>
            </Paper>
          </Grid>

          {/* Novos Contatos */}
          <Grid item xs={12} sm={6} md={4}>
            <Paper className={`${classes.card} ${classes.cardNovosContatos}`} elevation={0}>
              <Box>
                <Typography className={classes.cardTitle}>
                  NOVOS CONTATOS
                </Typography>
                <Typography className={classes.cardValue}>
                  {counters.leads}
                </Typography>
              </Box>
              <Box className={classes.cardIconContainer}>
                <PersonAddIcon />
              </Box>
            </Paper>
          </Grid>

          {/* Atendimento */}
          <Grid item xs={12} sm={6} md={4}>
            <Paper className={`${classes.card} ${classes.cardAtendimento}`} elevation={0}>
              <Box>
                <Typography className={classes.cardTitle}>
                  ATENDIMENTO
                </Typography>
                <Typography className={classes.cardValue}>
                  {formatTime(counters.avgSupportTime)}
                </Typography>
                <Typography className={classes.cardParagrafo}>
                                   </Typography>
              </Box>
              <Box className={classes.cardIconContainer}>
                <AccessAlarmIcon />
              </Box>
            </Paper>
          </Grid>

          {/* Espera */}
          <Grid item xs={12} sm={6} md={4}>
            <Paper className={`${classes.card} ${classes.cardEspera}`} elevation={0}>
              <Box>
                <Typography className={classes.cardTitle}>
               ESPERA
                </Typography>
                <Typography className={classes.cardValue}>
                  {formatTime(counters.avgWaitTime)}
                </Typography>
                <Typography className={classes.cardParagrafo}>
                                   </Typography>
              </Box>
              <Box className={classes.cardIconContainer}>
                <TimerIcon />
              </Box>
            </Paper>
          </Grid>

          {/* Finalizados */}
          <Grid item xs={12} sm={6} md={4}>
            <Paper className={`${classes.card} ${classes.cardFinalizados}`} elevation={0}>
              <Box>
                <Typography className={classes.cardTitle}>
                  FINALIZADOS
                </Typography>
                <Typography className={classes.cardValue}>
                  {counters.supportFinished}
                </Typography>
                <Typography className={classes.cardParagrafo}>
                                   </Typography>
              </Box>
              <Box className={classes.cardIconContainer}>
                <DoneAllIcon />
              </Box>
            </Paper>
          </Grid>
        </Grid>

        {/* Primeira linha de gráficos */}
        <Grid container spacing={3} style={{ marginTop: 20 }}>
          {/* Tabela de Atendentes */}
          <Grid item xs={12} md={6} style={{ display: 'flex' }}>
            <Paper className={classes.LPaper}>
              <Typography variant="h2" className={classes.headerTitle} style={{ marginBottom: '20px' }}>
                Atendentes Online            
              </Typography>
              {attendants.length ? (
                <TableAttendantsStatus attendants={attendants} loading={loading} />
              ) : null}
            </Paper>
          </Grid>

          {/* Total de Conversas por Usuários */}
          <Grid item xs={12} md={6} style={{ display: 'flex' }}>
            <Paper className={classes.LPaper}>
              <Typography variant="h2" className={classes.headerTitle} style={{ marginBottom: '20px' }}>
                Total de Conversas por Usuários
              </Typography>
              <ChatsUser />
            </Paper>
          </Grid>
        </Grid>

        {/* Gráfico de Atendimento Total */}
        <Grid container spacing={3} style={{ marginTop: 20 }}>
          <Grid item xs={12}>
            <Paper className={classes.LPaper}>
              <Typography variant="h2" className={classes.headerTitle} style={{ marginBottom: '20px' }}>
                Atendimento Total ({counters.supportTotal || counters.supportFinished || 0})
              </Typography>
              <ChartsDate />
            </Paper>
          </Grid>
        </Grid>

      </Container>
    </div>
  );
};

export default Dashboard;