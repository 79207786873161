import React, { useEffect, useState, useRef } from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Filler,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import brLocale from 'date-fns/locale/pt-BR';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { Button, Stack, TextField, Box } from '@mui/material'; // Importar Box
import { Typography } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles";

import api from '../../services/api';
import { format } from 'date-fns';
import { toast } from 'react-toastify';
import './button.css';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Filler
);

export const options = {
    responsive: true,
    maintainAspectRatio: false, // Permite controlar a altura separadamente
    plugins: {
        legend: {
            position: 'top',
            display: false, // Mantém a legenda oculta, conforme original
        },
        title: {
            display: false, // Removido o título do gráfico
        },
        tooltip: {
            enabled: true, // Tooltip ativado apenas no hover
            backgroundColor: '#FFFFFF', // Fundo branco
            borderColor: 'rgba(0, 0, 0, 0.1)', // Borda com transparência
            borderWidth: 1, // Largura da borda
            titleColor: '#000000', // Cor do título (data)
            titleFont: {
                family: 'Inter, sans-serif', // Fonte personalizada
                size: 14, // Tamanho do título
                weight: 'bold', // Negrito
            },
            bodyColor: '#6F6F6F', // Cor do texto principal
            bodyFont: {
                family: 'Inter, sans-serif',
                size: 14, // Tamanho do corpo do texto
            },
            padding: 10, // Espaçamento interno
            cornerRadius: 12, // Bordas arredondadas
            displayColors: false, // Exibe a cor do dataset como ícone
            usePointStyle: true, // Ícone em forma de círculo
            shadowOffsetX: 0, // Sombra horizontal
            shadowOffsetY: 4, // Sombra vertical
            shadowBlur: 10, // Intensidade do desfoque
            shadowColor: 'rgba(0, 0, 0, 0.1)', // Cor da sombra
            callbacks: {
                title: (context) => context[0].label,
                label: (context) => {
                    const label = context.dataset.label || '';
                    const value = context.raw.toLocaleString('pt-BR', { minimumFractionDigits: 1 });
                    return `${label}: ${value}`;
                },
            },
        },
    },
    interaction: {
        mode: 'index',
        intersect: false,
    },
    scales: {
        x: {
            title: {
                display: true,
                text: 'Data/Horário',
                font: {
                    family: 'Inter, sans-serif',
                    size: 14,
                },
            },
            ticks: {
                font: {
                    family: 'Inter, sans-serif',
                    size: 12,
                },
            },
            grid: {
                display: false, // Remove as linhas verticais
            },
        },
        y: {
            ticks: {
                beginAtZero: true,
                font: {
                    family: 'Inter, sans-serif',
                    size: 12,
                },
            },
            grid: {
                display: false, // Remove as linhas horizontais
            },
        },
    },
};

const useStyles = makeStyles((theme) => ({
    headerTitle: {
        color: "#00000",
        fontWeight: "bold",
        margin: 0,
        fontSize: "1.2rem"
    },
    filterContainer: {
        display: "flex",
        alignItems: "center",
        gap: "12px",
        flexWrap: "nowrap",
        marginBottom: theme.spacing(3),
        "& > *:not(:last-child)": {
            flex: 1,
        },
        "& > :last-child": {
            marginLeft: "auto",
        },
    },
    dateField: {
        "& .MuiOutlinedInput-root": {
            borderRadius: "8px",
            backgroundColor: "#FFFFFF",
            "& fieldset": {
                borderColor: "#E5E7EB",
            },
        },
        "& .MuiInputLabel-root": {
            color: "#6B7280",
            fontSize: "14px",
        },
        "& .MuiInputBase-input": {
            padding: "10px 14px",
            height: "20px",
            fontSize: "14px",
        },
    },
    filterButton: {
        backgroundColor: "#6F2CC0",
        color: "#FFFFFF",
        padding: "10px 24px",
        borderRadius: "8px",
        textTransform: "none",
        fontWeight: 600,
        fontSize: "14px",
        minWidth: "100px",
        height: "40px",
        marginLeft: "auto",
        "&:hover": {
            backgroundColor: "#5a23a0",
        },
    },
}));

export const ChartsDate = () => {
    const classes = useStyles();

    const [initialDate, setInitialDate] = useState(new Date());
    const [finalDate, setFinalDate] = useState(new Date());
    const [ticketsData, setTicketsData] = useState({ data: [], count: 0 });
    const chartRef = useRef(null);

    const [gradient, setGradient] = useState(null);

    const companyId = localStorage.getItem("companyId");

    useEffect(() => {
        handleGetTicketsInformation();
    }, []);

    useEffect(() => {
        const chart = chartRef.current;

        if (!chart) return;

        const ctx = chart.ctx;
        const chartArea = chart.chartArea;

        if (!chartArea) {
            // O gráfico ainda não foi renderizado
            return;
        }

        // Criar gradiente vertical
        const gradientFill = ctx.createLinearGradient(0, chartArea.top, 0, chartArea.bottom);
        gradientFill.addColorStop(0, 'rgba(162, 0, 255, 0.5)'); // Roxo sólido no topo
        gradientFill.addColorStop(1, 'rgba(162, 0, 255, 0)');   // Transparente na parte inferior

        setGradient(gradientFill);
    }, [ticketsData]);

    const dataCharts = {
        labels: ticketsData && ticketsData?.data.length > 0 && ticketsData?.data.map((item) => 
            item.hasOwnProperty('horario') 
                ? `Das ${item.horario}:00 às ${item.horario}:59` 
                : item.data
        ),
        datasets: [
            {
                label: 'Total de Conversas',
                data: ticketsData?.data.length > 0 && ticketsData?.data.map((item) => item.total),
                borderColor: '#6F2CC0', // Cor da linha
                backgroundColor: gradient || 'rgba(162, 0, 255, 0.2)', // Gradiente ou fallback
                fill: true, // Ativa o preenchimento
                tension: 0.4, // Suaviza as curvas
                pointBackgroundColor: '#6F2CC0', // Cor dos pontos
                datalabels: {
                    display: false, // Remove a contagem nos pontos
                },
            },
        ],
    };

    const handleGetTicketsInformation = async () => {
        // Validação de datas
        if (initialDate > finalDate) {
            toast.error('A data inicial não pode ser posterior à data final.');
            return;
        }

        try {
            const formattedInitialDate = format(initialDate, 'yyyy-MM-dd');
            const formattedFinalDate = format(finalDate, 'yyyy-MM-dd');

            const { data } = await api.get(`/dashboard/ticketsDay`, {
                params: {
                    initialDate: formattedInitialDate,
                    finalDate: formattedFinalDate,
                    companyId: companyId,
                }
            });
            setTicketsData(data);
        } catch (error) {
            console.error(error);
            toast.error('Erro ao buscar informações dos tickets');
        }
    }

    return (
        <>
            <div className={classes.filterContainer}>
                <TextField
                    variant="outlined"
                    label="Início"
                    type="date"
                    value={initialDate.toISOString().split('T')[0]}
                    onChange={(e) => setInitialDate(new Date(e.target.value))}
                    className={classes.dateField}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
                <TextField
                    variant="outlined"
                    label="Fim"
                    type="date"
                    value={finalDate.toISOString().split('T')[0]}
                    onChange={(e) => setFinalDate(new Date(e.target.value))}
                    className={classes.dateField}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
                <Button
                    variant="contained"
                    className={classes.filterButton}
                    onClick={handleGetTicketsInformation}
                >
                    FILTRAR
                </Button>
            </div>
            
            {/* Contêiner para controlar o tamanho do gráfico */}
            <Box sx={{ width: '100%', height: '300px' }}>
                <Line 
                    ref={chartRef}
                    options={options} 
                    data={dataCharts} 
                    style={{ width: '100%', height: '100%' }}
                />
            </Box>
        </>
    );
}